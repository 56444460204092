import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronUp,
  FaHome,
  FaUser,
  FaClock,
  FaProjectDiagram,
  FaTasks,
  FaFile,
  FaUserCircle,
} from "react-icons/fa";
import { IoLaptopOutline } from "react-icons/io5";
import { FcLeave } from "react-icons/fc";
import {
  MdTimeline,
  MdOutlineScreenshot,
  MdApps,
  MdSummarize,
  MdDynamicFeed,
} from "react-icons/md";
import {
  BsReception4,
  BsFillCalendarCheckFill,
  BsFillCalendar2WeekFill,
} from "react-icons/bs";
import { FaRegLightbulb } from "react-icons/fa";
import { BASEURL } from "./BASEURL";

const Sidenav = () => {
  const location = useLocation();
  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );
  const [master, ] = React.useState(localStorage.getItem("isAuth") && JSON.parse(localStorage.getItem("isAuth")).master);
  const [type, ] = React.useState(localStorage.getItem("isAuth") && JSON.parse(localStorage.getItem("isAuth")).type);

  return (
    <>
      <nav className="side-nav">
        <Link to={"/"} className="intro-x flex items-center px-3">
          {isAuth && isAuth.companydata && isAuth.companydata[0].orglogo !== "" ? (
            <>
            <img
              style={{maxWidth:"180px",maxHeight:"100px"}}
              src={BASEURL + `uploads/orglogo/${isAuth.companydata[0].orglogo}`}
              alt="logo"
              className="image-fit"
              srcSet=""
            />
            {isAuth.companydata[0].orglogo=="" &&
            <span className="hidden xl:block text-white  ml-3" style={{fontSize:"1rem",textAlign:"left"}}>
                
                {isAuth && isAuth.companydata[0].name} Tracking
              </span>
              }
              </>
            
          ) : (
            <>
              <img
                alt="Midone - HTML Admin Template"
                className="w-6"
                src="../images/logo.svg"
              />
              <span className="hidden xl:block text-white  ml-3" style={{fontSize:"1rem",textAlign:"left"}}>
                
                {isAuth && isAuth.companydata &&  isAuth.companydata[0].name} Tracking
              </span>
            </>
          )}
        </Link>
        <div className="side-nav__devider my-6"></div>
        <ul>
          <li>
            <Link
              to={"/"}
              className={
                location.pathname === "/"
                  ? "side-menu side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                <FaHome />
              </div>
              <div className="side-menu__title">
                Home
                <div className="side-menu__sub-icon transform rotate-180">
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
            {type!==2   ? (
              <>
            <div className="side-menu" onClick={() => setmenu3(!menu3)}>
              <div className="side-menu__icon">
                <FaProjectDiagram />
              </div>
              <div className="side-menu__title" style={{ cursor: "pointer" }}>
                Dashboard
                <div className="side-menu__sub-icon">
                  {menu3 ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
            </div>

            <ul className={menu3 ? "side-menu__sub-open" : null}>
            {type!==2   ? (
                <>
                  <li>
                    <Link
                      to="/dashboard/summary"
                      className={
                        location.pathname === "/dashboard/summary"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdSummarize />
                        </i>
                      </div>
                      <div className="side-menu__title">Summary</div>
                    </Link>
                  </li>
                </>
              ) : null}
              <li>
                <Link
                  to="/dashboard/desktop-activity"
                  className={
                    location.pathname === "/dashboard/desktop-activity"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <i data-lucide="activity">
                      <IoLaptopOutline />
                    </i>
                  </div>
                  <div className="side-menu__title">Desktop Activity</div>
                </Link>
              </li>
              {type!==2   ? (
                <>
                  <li>
                    <Link
                      to="/dashboard/app-usage"
                      className={
                        location.pathname === "/dashboard/app-usage"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdApps />
                        </i>
                      </div>
                      <div className="side-menu__title">App Usages</div>
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
            </> ) : null}
            {type!==2  ? (
              <>
                <div className="side-menu" onClick={() => setmenu1(!menu1)}>
                  <div className="side-menu__icon">
                    <FaRegLightbulb />
                  </div>
                  <div
                    className="side-menu__title"
                    style={{ cursor: "pointer" }}
                  >
                    App Insights
                    <div className="side-menu__sub-icon">
                      {menu1 ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                </div>

                <ul className={menu1 ? "side-menu__sub-open" : null}>
                  <li>
                    <Link
                      to="/insights/summary"
                      className={
                        location.pathname === "/insights/summary"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdSummarize />
                        </i>
                      </div>
                      <div className="side-menu__title">Summary</div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/insights/timeline"
                      className={
                        location.pathname === "/insights/timeline"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdTimeline />
                        </i>
                      </div>
                      <div className="side-menu__title">Timeline</div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/insights/productivity"
                      className={
                        location.pathname === "/insights/productivity"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <BsReception4 />
                        </i>
                      </div>
                      <div className="side-menu__title">Productivity</div>
                    </Link>
                  </li>
                  {type!==2 &&<>
                  <li>
                    <Link
                      to="/insights/screenshots"
                      className={
                        location.pathname === "/insights/screenshots"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdOutlineScreenshot />
                        </i>
                      </div>
                      <div className="side-menu__title">Screenshots</div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/insights/applications"
                      className={
                        location.pathname === "/insights/applications"
                          ? "side-menu side-menu--active"
                          : "side-menu"
                      }
                    >
                      <div className="side-menu__icon">
                        <i data-lucide="activity">
                          <MdApps />
                        </i>
                      </div>
                      <div className="side-menu__title">Applications</div>
                    </Link>
                  </li>
                  </> }
                </ul>
                </>
            ) : null}
            
              {type!==2 &&<>
                <Link
                  to={"/employees"}
                  className={
                    location.pathname === "/employees"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <FaUser />
                  </div>
                  <div className="side-menu__title">
                    Employee
                    <div className="side-menu__sub-icon transform rotate-180">
                      <i data-lucide="chevron-down"></i>
                    </div>
                  </div>
                </Link>
                </>
            }
             

            {master!==1 &&<>
            <Link
              to={"/punch-ticket"}
              className={
                location.pathname === "/punch-ticket"
                  ? "side-menu side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                <FcLeave />
              </div>
              <div className="side-menu__title">
                Punch Ticket
                <div className="side-menu__sub-icon transform rotate-180">
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
           
            <Link
              to={"/leaves"}
              className={
                location.pathname === "/leaves"
                  ? "side-menu side-menu--active"
                  : "side-menu"
              }
            >
              <div className="side-menu__icon">
                <FcLeave />
              </div>
              <div className="side-menu__title">
                Leaves
                <div className="side-menu__sub-icon transform rotate-180">
                  <i data-lucide="chevron-down"></i>
                </div>
              </div>
            </Link>
          

            <Link
              to="#"
              className={"side-menu"}
              onClick={() => setmenu2(!menu2)}
            >
              <div className="side-menu__icon">
                <FaClock />
              </div>
              <div className="side-menu__title">
                Attendance
                <div className="side-menu__sub-icon transform rotate-180">
                  {!menu2 ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>
            </Link>

            <ul className={menu2 ? "side-menu__sub-open" : null}>
              {isAuth.role !== 2 ?
              <li>
                <Link
                  to="/attendance/attendance-details"
                  className={
                    location.pathname === "/attendance/attendance-details"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <i data-lucide="activity">
                      <BsFillCalendarCheckFill />
                    </i>
                  </div>
                  <div className="side-menu__title">Attendance Details</div>
                </Link>
              </li>
              :null}
              <li>
                <Link
                  to="/attendance/monthly-records"
                  className={
                    location.pathname === "/attendance/monthly-records"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <i data-lucide="activity">
                      <BsFillCalendar2WeekFill />
                    </i>
                  </div>
                  <div className="side-menu__title">Monthly Records</div>
                </Link>
              </li>
            </ul>
          </>}
            {isAuth.role === 0 ? (
              <>
               {master!==1 &&<>
                <Link
                  to={"/feeds"}
                  className={
                    location.pathname === "/feeds"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <MdDynamicFeed />
                  </div>
                  <div className="side-menu__title">
                    Feeds
                    <div className="side-menu__sub-icon transform rotate-180">
                      <i data-lucide="chevron-down"></i>
                    </div>
                  </div>
                </Link>

                <Link
                  to={"/employee-activity"}
                  className={
                    location.pathname === "/employee-activity"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <FaTasks />
                  </div>
                  <div className="side-menu__title">
                    Employee Activity
                    <div className="side-menu__sub-icon transform rotate-180">
                      <i data-lucide="chevron-down"></i>
                    </div>
                  </div>
                </Link>
              

                <Link
                  to={"/report"}
                  className={
                    location.pathname === "/report"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <FaFile />
                  </div>
                  <div className="side-menu__title">
                    Report
                    <div className="side-menu__sub-icon transform rotate-180">
                      <i data-lucide="chevron-down"></i>
                    </div>
                  </div>
                </Link>
                </>}

                <Link
                  to={"/admin"}
                  className={
                    location.pathname === "/admin"
                      ? "side-menu side-menu--active"
                      : "side-menu"
                  }
                >
                  <div className="side-menu__icon">
                    <FaUserCircle />
                  </div>
                  <div className="side-menu__title">
                    Admin
                    <div className="side-menu__sub-icon transform rotate-180">
                      <i data-lucide="chevron-down"></i>
                    </div>
                  </div>
                </Link>
              </>
            ) : null}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidenav;
