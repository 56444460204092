import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from 'react-redux';

const SummaryHoursWiseProductivity = (props) => {
  let [loading, setLoading] = useState(false);
  const [hrsdata, sethrsdata] = useState([]);

  const [config, setconfig] = useState({});
  const istheme = useSelector(state => state.auth.currentTheme)

  useEffect(() => {
    getAppData(format(new Date(props.date), "yyyy-MM-dd"), props.username);
  }, [istheme]);

  useEffect(() => {
    getAppData(format(new Date(props.date), "yyyy-MM-dd"), props.username);
  }, [props.username, props.date]);

  const getAppData = async (date, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-HourlyProductivity/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master:JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          sethrsdata(res.hourarr);

          if (res.hourarr.length > 0) {
            if(istheme == 1){
              setconfig({
                chart: {
                  type: "column",
                  backgroundColor: '#1B253B',
                  style: {
                    color: "#fff"
                  }
                },
                plotOptions: {
                  column: {
                      colorByPoint: true
                  }
                },
                colors: [
                  '#FFC435', 
                  ],
                credits: {
                  enabled: false,
                },
                title: {
                  text: "Hourly Productivity",
                  style: {
                    color: "#fff"
                  }
                },
                xAxis: {
                  categories: res.hourarr,
                  title: {
                    text: "",
                  },
                },
                series: [
                  {
                    name: "Productivity (%)",
                    data: res.data,
                  },
                ],
              });
            }else{
              setconfig({
                chart: {
                  type: "column",
                  backgroundColor: '#fff',
                  style: {
                    color: "#000"
                  }
                },
                plotOptions: {
                  column: {
                      colorByPoint: true
                  }
                },
                colors: [
                  '#FFC435', 
                  ],
                credits: {
                  enabled: false,
                },
                title: {
                  text: "Hourly Productivity",
                  style: {
                    color: "#000"
                  }
                },
                xAxis: {
                  categories: res.hourarr,
                  title: {
                    text: "",
                  },
                },
                series: [
                  {
                    name: "Productivity (%)",
                    data: res.data,
                  },
                ],
              });
            }
          }
        } else {
          sethrsdata([]);
          setconfig({});
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
          <HashLoader
            color="#5755d9"
            size={30}
            style={{ position: "absolute", top: "50%", right: "50%" }}
          />
        </div>
      ) : (
        <div className="intro-y grid grid-cols-12 gap-5 mt-2">
          <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
            {hrsdata && hrsdata.length > 0 ? (
              <HighchartsReact highcharts={Highcharts} options={config} />
            ) : (
              <div className="box p-5 text-danger">No Data Available</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryHoursWiseProductivity;
