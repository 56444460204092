import React,{useState, useEffect} from 'react'
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import Webcam from "react-webcam";
import { useCallback, useRef, } from "react"; // import useRef

const WebcamComponent = () => <Webcam />
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}

const JobApplication = (props) => {
    const [data, setdata] = useState({
      fullname: "",
      email: "",
      contactno: "",
      city: 1,
      address:"",      
      position: "",
      noticeperiod: "",      
      cv:""
    });
  
    let [loading, setLoading] = useState(false); 
    const [selectedFile, setSelectedFile] = useState(null);
    // const webcamRef = useRef(null); // create a webcam reference
    const [imgSrc, setImgSrc] = useState(null); // initialize it
    const [iscapture, setiscapture] = useState(false); // initialize it


    const [picture, setPicture] = useState('')
  const webcamRef = React.useRef(null)
  const capture = React.useCallback(() => {
    setiscapture(true)
    const pictureSrc = webcamRef.current.getScreenshot()
    // console.log(pictureSrc);
    setPicture(pictureSrc)
  })
  const setRetake = (() => {
    setPicture("")
    capture()
  })

    useEffect(() => {
        document.body.classList.add("login");
    }, [])

    const handlechange = (e) => {
    
        let value = e.target.value;
        if (e.target.name === "status") {
          value = e.target.checked;
        }
        setdata({
          ...data,
          [e.target.name]: value,
        });
        // console.log(data);
      };
    const handleFileInputChange = (e) => {
        setSelectedFile(e.target.files[0]);
        let value = e.target.value;
        setdata({
            ...data,
            [e.target.name]: value,
          });
    };

    // const capture = useCallback(() => {
    //   const imageSrc = webcamRef.current.getScreenshot();
    //   console.log(imageSrc);
    //   setImgSrc(imageSrc);
    // }, [webcamRef]);

    const savedata = async (elm) => {
      // console.log(selectedFile); return
        if (
          data.fullname === "" ||
          data.email === "" ||
          data.contactno === "" ||
          data.city === "" ||
          data.position === "" ||
          data.noticeperiod === ""
        ) {
          swal({
            title: "Error",
            text: "Please Enter value Required value",
            icon: "error",
            timer: 2000,
          });
          return;
        }
        // console.log(data); return
        
        setLoading(true);

        const formData = new FormData();
        formData.append("cv", selectedFile);
        formData.append("data", JSON.stringify(data));        
        formData.append("picture", picture);        

        const res = await axios.post(BASEURL + "job-application", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

        // const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // await fetch(BASEURL + `job-application/`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        //   body: JSON.stringify({
        //     data,
        //     selectedFile
        //   }),
        // })
        //   .then((response) => response.json())
        //   .then((res) => {
            if (res.data.status === 201) {
              setdata({
                fullname: "",
                email: "",
                contactno: "",
                city: 1,
                address:"",      
                position: "",
                noticeperiod: "",      
                cv:""
              });
              setPicture("")
              setiscapture(false)
              swal({
                title: "Job application submitted successfully",
                text: res.message,
                icon: "success",
                timer: 2000,
              });
            } else {
              swal({
                title: "Error occured! Please try again",
                text: res.message,
                icon: "error",
                timer: 2000,
              });
            }
            setLoading(false);
        //   })
        //   .catch((err) => console.log(err));
        
      };
    
  return (
    <div className="p-0">
    <div className="container " style={{
          height: "100vh",
          overflow: "scroll"
    }}>
      <div className="block xl:grid grid-cols-2 ">
        <div className="hidden xl:flex flex-col min-h-screen">
          <div className="-intro-x flex items-center pt-5">
            <img
              alt="Midone - HTML Admin Template"
              className="w-6"
              src="../images/logo.svg"
            />
            <span className="text-white text-lg ml-3">Jenya Tracking</span>
          </div>
          <div className="my-auto">
            <img alt='' className="-intro-x w-1/2 " src="../images/illustration.svg"/>
            <div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
              A few more clicks to
              <br />
              submit your job application form
            </div>         
          </div>
        </div>

        <div className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0" style={{overflow:"scroll"}}>
          <div className="">
            <div className="grid grid-cols-24 gap-12 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="text-base mr-auto" style={{fontSize:"25px",fontWeight:500}}>
                                Job Application
                                </h2>                               
                            </div>
                            <div id="form-validation" className="p-5">
                                <div className="preview">
                                    
                                <div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
              <form className='pb-5 mb-5'>
                <div className="grid grid-cols-12 gap-x-5 text-left">
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Full Name <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.fullname}
                        required
                        type="text"
                        name="fullname"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </div>
                  </div>
               
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Email ID <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.email}
                        required
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Contact Number <span className="text-danger">(*)</span> 
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.contactno}
                      required
                      minLength={10}
                      maxLength={13}
                      type="number"
                      name="contactno"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>

                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      City <span className="text-danger">(*)</span> 
                    </label>
                    <select
                      className="form-select"
                      name="city"
                      onChange={handlechange}
                      value={data.city}
                    >
                     
                      <option value="0">Ahmedabad</option>
                      <option value="1">Gandhinagar</option>
                      <option value="1">Other</option>
                    </select>
                  </div>

                  <div className="col-span-12 2xl:col-span-12">
                    <label htmlFor="update-profile-form-2" className="form-label">
                        Address
                    </label>
                    <br />
                    <textarea
                        name="address"
                        className="form-control"
                        cols="80"
                        rows="2"
                        onChange={handlechange}
                        value={data && data.address}
                    ></textarea>
                    </div>
                  
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Position Applied for <span className="text-danger">(*)</span> 
                      </label>
                      <select
                          className="form-select"
                          name="position"
                          onChange={handlechange}
                          value={data.position}
                        >
                        
                          <option value="">Select</option>
                          <option value="0">Python Developer</option>
                          <option value="1">React JS Developer</option>
                          <option value="2">PHP Developer</option>
                          <option value="3">HR Executive</option>
                          <option value="4">Angular Developer</option>
                          <option value="5">SEO Executive</option>
                          <option value="6">Admin</option>
                        </select>
                      {/* <input
                        id="update-profile-form-1"
                        value={data.position}
                        required
                        type="text"
                        name="position"
                        className="form-control"
                        onChange={handlechange}
                      /> */}
                    </div>
                  </div>
                  
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Notice Period: <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.noticeperiod}
                        required
                        type="text"
                        name="noticeperiod"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </div>
                  </div>
              
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Upload CV:
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.cv}
                        required
                        type="file"
                        name="cv"
                        className="form-control p-1"
                        onChange={handleFileInputChange}
                      />
                    </div>
                  </div>
              
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Upload Image:
                      </label>
                      <div className="container">
                      {iscapture  && 
                      picture == '' ? (
                          <Webcam
                            audio={false}
                            height={200}
                            ref={webcamRef}
                            width={200}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                          />
                        ) : (
                          <img src={picture} />
                        )}
                        {/* <div className="btn-container">
                          <button type='button' onClick={capture}>Capture photo</button>
                        </div> */}

                        {picture != '' ? (
                                  <button
                                  type='button'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      // setPicture()
                                      setRetake()
                                    }}
                                    className="btn btn-primary"
                                  >
                                    Retake Image
                                  </button>
                                ) : (
                                  <button
                                    type='button'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      capture()
                                    }}
                                    className="btn btn-danger"
                                  >
                                    Capture Image
                                  </button>
                                )}

                      </div>

                    </div>
                  </div>
                </div>
                 
                {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", left: "10%",paddingTop: "10%" }}
            />
          ) : 

                <div className="text-left">
                <button
                  type="button"
                  onClick={() => savedata()}
                  className="btn btn-primary w-50 mt-3 mb-5"
                >
                  Submit Application
                </button> 
                </div>        
        }       
              </form>
            </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default JobApplication