import React, { useState, useEffect } from "react";
import { BASEURL } from "./BASEURL";
import "react-toastify/dist/ReactToastify.css";
import HashLoader from "react-spinners/HashLoader";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import format from 'date-fns/format'

import {
  FaSignInAlt,
  FaTrashAlt,
  FaEdit
} from "react-icons/fa";
import { Link } from "react-router-dom";


function Masteradmin(props) {
  const [isadd, setisadd] = useState(false);
  const [getdata, setgetdata] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [data, setdata] = useState({
    name: "",
    email: "",
    mobile: "",
    user: 0,
    expiry: new Date(),
    uuid: null,
  });
  const [update, setupdate] = useState({
    msg: "Add organization",
    data: {},
  });
  let [loading, setLoading] = useState(false);
  let [selectedorg, setselectedorg] = useState("");
  let [msg, setmsg] = useState(false);

  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);

  useEffect(() => {
    getData();
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
  }, []);

  
  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % items.length;

    setItemOffset(newOffset);
  };
    
  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
  }, [itemOffset]);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `addorganization`, {
      method: "GET",
      headers: myHeaders,
    });
    let data2 = await data.json();
    if (data2.status === 201) {
      let arr = [];
      let arr1 = [];
      for (const key in data2.data) {
        arr.push(key);
        if (key < 9) {
          arr1.push(key);
        }
      }
      setitems(arr);

      setCurrentItems(arr1);

      setPageCount(Math.ceil(arr.length / 10));

      setuserdata(data2.data);
      setbkpdata(data2.data);
    }
    setgetdata(data2.data);
    setLoading(false);
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setuserdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 10));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 10) % matches.length;
      setItemOffset(newOffset);
      setuserdata(matches);
      const endOffset = itemOffset + 10;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 10));
    }
  };

  const editdata = (data) => {
    setisadd(true);
    let status;
    if (data.active === 1) {
      status = true;
    } else {
      status = false;
    }
    // console.log(data.companyuuid);
    setdata({
      id: data.user_id,
      name: data.name,
      email: data.support_contact,
      mobile: data.phone,
      user: data.user_count,
      expiry: data && data.expiry_date.substring(0, 10),
      uuid: data.uuid,
      // password: data.password,
      status: status,
    });
  };

  
  const deletedata = (id) => {
    swal({
      title: "Delete",
      text: `Please confirm if you want to Delete Organization?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `delete-org/${id}`, {
          method: "DELETE",
          headers: myHeaders,         
        })
          .then((response) => response.json())
          .then((res) => {
            getData();
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  const selectoption = (e) => {
    // let data = getdata[e.target.value];
    if(e.target.value===""){
      setselectedorg("");
      setdata({
        name: "",
        email: "",
        mobile: "",
        user: 0,
        expiry: new Date(),
        uuid: null,
      })
    }else{
      setselectedorg(e.target.value);
    let data = getdata && getdata.filter((i) => [e.target.value].includes(i.uuid))[0];
    setdata({
      name: data.name,
      email: data.support_contact,
      mobile: data.phone,
      user: data.user_count,
      expiry: data && data.expiry_date.substring(0, 10),
      uuid: data.uuid,
    });
  
    setupdate({ ...update, msg: "Update detail" });
  }
  };

  const savedata = async () => {
    // props.getData(); return
     
    if (
      data.name === "" ||
      // data.department === "" ||
      // data.hod === "" ||
      data.mobile === "" ||
      data.email === "" ||
      data.expiry === ""
    ) {
      swal({
        title: "Error",
        text: "Please Enter value Required value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setisadd(!isadd)
    setLoading(true);
    setselectedorg("");
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    let data2 = await fetch(BASEURL + `addorganization`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        data,
        username: userdata.username,
        company_uuid: userdata['companydata'][0]['uuid'],
      }),
    });

    let data3 = await data2.json();
    if (data2.status === 201) {
      swal({
        title: "Success",
        text: data3.message,
        icon: "success",
        timer: 2000,
      });
      setmsg(data3.message);
      getData();
      setdata({
        name: "",
        email: "",
        mobile: "",
        user: 0,
        expiry: new Date(),
        uuid: null,
      })

      // props.getData();

      setTimeout(() => {
        setmsg("");
      }, 3000);
    } else {
      setmsg(data3.message);
      setTimeout(() => {
        setmsg("");
      }, 3000);
    }

    setLoading(false);
  };

  const handlechange = (e) => {
    
    let value = e.target.value;
    if (e.target.name === "status") {
      value = e.target.checked;
    }
    setdata({
      ...data,
      [e.target.name]: value,
    });
    // console.log(data);
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">All Organization</h2>
        <div className="flex items-center mx-2">
        <button
            type="button"
            onClick={() => setisadd(!isadd)}
            className="btn btn-primary w-50 mr-2"
          >
            Add Organization
          </button>
          {/* <select
            className="form-select ml-3"
            style={{ fontSize: "14px", width: "200px"}}
            onChange={(e) => selectoption(e)}
            value={selectedorg}
          >
              <option value="" key="">
                    Select Organization
                  </option>
            {getdata.length > 0 &&
              getdata.map((ele, i) => {
                return (
                  <option value={ele.uuid} key={i}>
                    {ele.name}
                  </option>
                );
              })}
          </select> */}
        </div>
        {/* <div>
          <button
            type="button"
            onClick={() => {
              setdata({
                name: "",
                email: "",
                mobile: 0,
                user: 0,
                expiry: new Date(),
              });
              setupdate({ msg: "Add organization", data: {} });
            }}
            className="btn btn-primary w-100 "
          >
            Add organization
          </button>
        </div> */}
      </div>
      {isadd && 
      // (
      //   <div className="text-center m-5 p-5">
      //     <HashLoader
      //       color="#5755d9"
      //       size={30}
      //       style={{ position: "absolute", right: "50%" }}
      //     />
      //   </div>
      // ) : (
        <div className="p-5">
          <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-6">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Organization Name*
                    </label>
                    <input
                      className="form-control p-2 border"
                      onChange={(e) =>
                        setdata({ ...data, name: e.target.value })
                      }
                      value={data.name}
                    />
                  </div>

                  <div className="mt-3">
                    <label className="form-label">Email</label>
                    <input
                      value={data.email}
                      type="email"
                      onChange={(e) =>
                        setdata({ ...data, email: e.target.value })
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="mt-3">
                    <label>Expiry</label>
                    <div className="form-control">
                      <input
                        type="date"
                        value={data.expiry}
                        onChange={(e) =>
                          setdata({ ...data, expiry: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                  {/* <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-100 mt-3"
              > */}
                
                {/* {update.msg} */}
              {/* </button> */}
              </div>
                </div>
                <div className="col-span-12 2xl:col-span-6">
                  <div className="mt-3 2xl:mt-0">
                    <label className="form-label">Mobile no.</label>
                    <input
                      value={data.mobile}
                      id="update-profile-form-6"
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        setdata({ ...data, mobile: e.target.value })
                      }
                    />
                  </div>
                  <div className="mt-3">
                    <label>User count</label>
                    <div className="form-control">
                      <input
                        type="number"
                        value={data.user}
                        onChange={(e) =>
                          setdata({ ...data, user: e.target.value })
                        }
                        className="form-check-input"
                      />
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div className="">
                      <label>Status</label>
                      <div className="form-switch mt-2">
                        {loading ? (
                          <div className="text-center m-5 p-5">
                            <HashLoader
                              color="#5755d9"
                              size={30}
                              style={{ position: "absolute", right: "50%" }}
                            />
                          </div>
                        ) : data && data.status === false ? (
                          <input
                            type="checkbox"
                            name="status"
                            onChange={handlechange}
                            className="form-check-input"
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name="status"
                            onChange={handlechange}
                            value={data.status}
                            checked
                            className="form-check-input"
                          />
                        )}
                      </div>
                    </div>
                  </div>
               
                  <button
                  type="button"
                  onClick={() => savedata()}
                  className="btn btn-primary w-20 mt-3"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setisadd(!isadd)}
                  className="btn btn-danger w-20 mt-3 ml-2"
                >
                  Cancel
                </button>


                </div>
              </div>
             
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          </div>
        </div>
      }

    <div className="flex flex-col sm:flex-row sm:items-end xl:items-start pt-2 pr-5 pl-5 mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
          {/* {master == true ? (
            <div className="flex mt-5 sm:mt-0">
              <select
              name="uuid"
                onChange={handlechange}
                className="form-control"
                id=""
              >
                <option value="1">Active</option>
                <option value="2">In-Active</option>
              </select>
            </div>
          ) : null} */}
          <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {userdata.length > itemOffset + 10 ? itemOffset + 10 : userdata.length} of {userdata.length} entries
          </div>
        </div>
        <div className="overflow-x-auto pr-5 pl-5">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">#</th>
                  <th className="whitespace-nowrap">Name</th>
                  <th className="whitespace-nowrap">Contact No.</th>
                  <th className="whitespace-nowrap">Support Contact</th>
                  <th className="whitespace-nowrap">Employee Count</th>
                  <th className="whitespace-nowrap">Expiry Date</th>
                  <th className="whitespace-nowrap">Created At </th>
                  <th className="whitespace-nowrap">Status</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{userdata[item].id}</td>
                        <td>
                            <u>{userdata[item].name}</u>                          
                        </td>
                        <td>{userdata[item].phone}</td>
                        <td>{userdata[item].support_contact}</td>
                        <td>{userdata[item].user_count}</td>
                        <td>{userdata[item] && userdata[item].expiry_date!=null ? new Date(userdata[item].expiry_date).toISOString().substring(0, 10):"-"}</td>
                        <td>{userdata[item] && userdata[item].created_at!=null ? userdata[item].created_at.substring(0,10):"-"}</td>
                        <td>
                          {userdata[item].active === 1 ? "Active" : "Inactive"}
                        </td>
                        <td>
                          <div className="flex lg:justify-center items-center cursor-pointer">
                         
                            {userdata[item].type!==0?<>
                            <FaEdit title="Edit" style={{color:'gray'}} className="mr-2" onClick={() => editdata(userdata[item])} />
                            <FaTrashAlt onClick={() => deletedata(userdata[item].uuid)} title="Delete" className="text-danger mr-2"/>                            
                            {/* <FaSignInAlt onClick={() => signindata(userdata[item])} title={"Sign In as "+ userdata[item].name} style={{color:'#1E40AF'}} className="mr-2"/>  */}
                            </>:"-" }                            
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : (
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              )}
            </nav>
          </div>
        </div>
    </div>
  );
}

export default Masteradmin;
