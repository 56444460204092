import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";

const WorkingShifts = () => {
  const [shiftdata, setshiftdata] = useState(null);
  const [timezone, settimezone] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [data, setdata] = useState({
    id: "",
    title: "",
    working_hrs: "",
    breaks: "",
    max_punchin_time: "",
    timezone: "",
    is_screenshot_enabled: true,
    screenshot_interval: 2,
    productivity_enabled: true,
    is_auto_punch_out: false,
    auto_punch_out_time: "",
    is_att_regularization: false,
    att_regularization_limit: "",
    status: true,
  });

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);

  useEffect(() => {
    getShiftData();
    gettimezone();
  }, []);

  const gettimezone = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== undefined) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllTimezones/`, {
      method: "POST",
      headers: myHeaders,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          settimezone(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getShiftData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getShiftData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 1,
        uuid: userdata.companydata[0].uuid,

      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setshiftdata(res.data);
          setbkpdata(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handlechange = (e) => {
    let value = e.target.value;
    if (e.target.name === "status") {
      value = e.target.checked;
    }
    if (e.target.name === "is_auto_punch_out") {
      value = e.target.checked;
    }
    if (e.target.name === "is_att_regularization") {
      value = e.target.checked;
    }
    if (e.target.name === "is_screenshot_enabled") {
      value = !e.target.checked;
    }
    if (e.target.name === "productivity_enabled") {
      value = !e.target.checked;
    }
    setdata({ ...data, [e.target.name]: value });
  };

  const savedata = async () => {
    if (data.title === "" || data.working_hrs === "" || data.breaks === "") {
      swal({
        title: "Error",
        text: "Please Enter value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `add-shift/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmsg(res.message);
          getShiftData();
          setdata({
            title: "",
            working_hrs: "",
            breaks: "",
            max_punchin_time: "",
            timezone: "",
            is_screenshot_enabled: true,
            screenshot_interval: 2,
            productivity_enabled: true,
            is_auto_punch_out: false,
            auto_punch_out_time: "",
            is_att_regularization: false,
            att_regularization_limit: "",
            status: true,
          });
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            timer: 2000,
          });
          setTimeout(() => {
            setmsg("");
            setisadd(!isadd);
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const editdata = (data) => {
    setisadd(true);
    let status;
    let is_screenshot_enabled;
    let productivity_enabled;
    let is_auto_punch_out;
    let is_att_regularization;
    if (data.active === 1) {
      status = true;
    } else {
      status = false;
    }
    if (data.is_screenshot_enabled === 1) {
      is_screenshot_enabled = true;
    }
    if (data.is_screenshot_enabled === 0) {
      is_screenshot_enabled = false;
    }
    if (data.productivity_enabled === 1) {
      productivity_enabled = true;
    } else {
      productivity_enabled = false;
    }
    if (data.is_auto_punch_out === 1) {
      is_auto_punch_out = true;
    } else {
      is_auto_punch_out = false;
    }
    if (data.is_att_regularization === 1) {
      is_att_regularization = true;
    } else {
      is_att_regularization = false;
    }

    setdata({
      id: data.shift_id,
      title: data.title,
      working_hrs: data.working_hrs,
      breaks: data.breaks,
      max_punchin_time: data.max_punchin_time,
      timezone: data.timezone,
      is_screenshot_enabled: is_screenshot_enabled,
      screenshot_interval: data.screenshot_interval,
      productivity_enabled: productivity_enabled,
      is_auto_punch_out: is_auto_punch_out,
      auto_punch_out_time: data.auto_punch_out_time,
      is_att_regularization: is_att_regularization,
      att_regularization_limit: data.att_regularization_limit,
      status: status,
    });
    // console.log(data);
  };

  const adddata = (data) => {
    setisadd(!isadd);
    setdata({
      title: "",
      working_hrs: "",
      breaks: "",
      max_punchin_time: "",
      timezone: "",
      is_screenshot_enabled: true,
      screenshot_interval: 2,
      productivity_enabled: true,
      is_auto_punch_out: false,
      auto_punch_out_time: "",
      is_att_regularization: false,
      att_regularization_limit: "",
      status: true,
    });
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      setshiftdata(bkpdata);
    } else {
      var matches = shiftdata.filter(function (s) {
        return s.title.toLowerCase().includes(`${event.target.value}`);
      });
      if (matches.length > 0) {
        setshiftdata(matches);
      } else {
        setshiftdata(bkpdata);
      }
    }
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">All Shifts</h2>
        <button
          type="button"
          onClick={() => adddata()}
          className="btn btn-primary w-50"
        >
          Add shift
        </button>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Title*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.title}
                      type="text"
                      name="title"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Working Hours*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.working_hrs}
                      type="number"
                      min={0}
                      name="working_hrs"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Break(In hours)*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.breaks}
                      type="number"
                      min={0}
                      name="breaks"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Max Punch In Time
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.max_punchin_time}
                      type="time"
                      name="max_punchin_time"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    Timezone*
                  </label>
                  <select
                    className="form-select"
                    name="timezone"
                    onChange={handlechange}
                    value={data.timezone}
                  >
                    <option value="">Select Timezone</option>

                    {timezone &&
                      timezone.map((item, i) => {
                        return (
                          <option value={item.gmtoffset}>{item.gmtzone}</option>
                        );
                      })}
                  </select>
                </div>

                <div className="col-span-12 2xl:col-span-6 mt-3"></div>
                <div className="col-span-12 2xl:col-span-2 mt-3">
                  <div className="mt-3">
                    <label>Auto Punch Out</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="is_auto_punch_out"
                        checked={data.is_auto_punch_out === true}
                        onChange={handlechange}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-2 mt-5">
                  {data && data.is_auto_punch_out === true ? (
                    <>
                      <label
                        htmlFor="update-profile-form-1 "
                        className="form-label"
                      >
                        Auto Punch Out Time
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.auto_punch_out_time}
                        type="time"
                        name="auto_punch_out_time"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </>
                  ) : null}
                </div>

                <div className="col-span-12 2xl:col-span-7 mt-3"></div>

                <div className="col-span-12 2xl:col-span-2">
                  <div className="mt-3">
                    <label>Attendance Regularization</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="is_att_regularization"
                        checked={data.is_att_regularization === true}
                        onChange={handlechange}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-2 mt-3">
                  {data && data.is_att_regularization === true ? (
                    <>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Monthly Attendance Limit*
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.att_regularization_limit}
                        type="number"
                        min={0}
                        name="att_regularization_limit"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </>
                  ) : null}
                </div>

                <div className="col-span-12 2xl:col-span-7 mt-3"></div>

                <div className="col-span-12 2xl:col-span-2 mt-3">
                  <div className="mt-3">
                    <label>Screenshot Enabled</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="is_screenshot_enabled"
                        checked={data.is_screenshot_enabled === false}
                        onChange={handlechange}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-7 mt-3"></div>

                <div className="col-span-12 2xl:col-span-2 mt-3">
                  <div className="mt-3">
                    <label>Productivity Enabled</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="productivity_enabled"
                        onChange={handlechange}
                        checked={data.productivity_enabled === false}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-2 mt-3"></div>

                <div className="col-span-12 2xl:col-span-2 mt-3">
                  <div className="mt-3">
                    <label>Status</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="status"
                        onChange={handlechange}
                        checked={data.status === true}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-20 mt-3"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => adddata()}
                className="btn btn-danger w-20 mt-3 ml-2"
              >
                Cancel
              </button>
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">#</th>
                  <th className="whitespace-nowrap">Title</th>
                  <th className="whitespace-nowrap">Working Hrs.</th>
                  <th className="whitespace-nowrap">Breaks</th>
                  <th className="whitespace-nowrap">
                    Max. Punchin <br /> Time
                  </th>
                  <th className="whitespace-nowrap">
                    Screenshot <br /> Enabled
                  </th>
                  <th className="whitespace-nowrap">
                    Productivity <br /> Enabled
                  </th>
                  <th className="whitespace-nowrap">Status</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
                {shiftdata && shiftdata.length > 0 ? (
                  shiftdata.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.shift_id}</td>
                        <td>{item.title}</td>
                        <td>{item.working_hrs}</td>
                        <td>{item.breaks}</td>
                        <td>{item.max_punchin_time}</td>
                        <td>
                          {item.is_screenshot_enabled === 1 ? "Yes" : "No"}
                        </td>
                        <td>
                          {item.productivity_enabled === 1 ? "Yes" : "No"}
                        </td>
                        <td>{item.active === 1 ? "Active" : "Inactive"}</td>
                        <td>
                          <div className="flex lg:justify-center items-center">
                            <div
                              className="edit flex items-center mr-3"
                              onClick={() => editdata(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="check-square"
                                data-lucide="check-square"
                                className="lucide lucide-check-square w-4 h-4 mr-1"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                              </svg>{" "}
                              Edit
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9}>No data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkingShifts;
