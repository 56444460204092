import React, { useState, useEffect } from "react";
import OrgDetails from "./OrgDetails";
import Departments from "./Departments";
import Employee from "./Employee";
// import Designations from "./Designations";
import WorkingShifts from "./WorkingShifts";
import DesktopApps from "./DesktopApps";
import Notifications from "./Notifications";
import Holidays from "./Holidays";
import Alert from "./Alert";
import LeaveType from "./LeaveType";
import AccountSettings from "./AccountSettings";
import { useLoadingContext } from "react-router-loading";
import Masteradmin from "../Masteradmin";
import JobApplication from "../admin/JobApplications";

const Profile = () => {
  const loadingContext = useLoadingContext();
  let [tabtype, settabtype] = useState(JSON.parse(localStorage.getItem("isAuth")).master==1 ? 3 : 0 );
  let [master, setmaster] = useState(0);

  useEffect(() => {
    setmaster(JSON.parse(localStorage.getItem("isAuth")).master);
    const loading = async () => {
      loadingContext.done();
    };

    loading();
  }, [loadingContext]);

  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 lg:col-span-2 2xl:col-span-2 flex lg:block flex-col-reverse">
          <div className="intro-y box p-3 mt-5">
            <div className="mt-1" style={{ cursor: "pointer" }}>
              {master === 0 ? <>
              <div
                onClick={() => settabtype(0)}
                className={
                  tabtype === 0
                    ? "flex items-center  p-2 rounded-md activebg"
                    : "flex items-center p-2  rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/org-detail-icon.png"
                  alt=""
                />
                <div className="text-left "> Organization Details </div>
              </div>
              
              <div
                onClick={() => settabtype(1)}
                className={
                  tabtype === 1
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/department-icon.png"
                  alt=""
                />
                <div className="text-left">Departments </div>
              </div>
{/* 
              <div
                onClick={() => settabtype(2)}
                className={
                  tabtype === 2
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/designation-icon.png"
                  alt=""
                />
                Designations
              </div> */}
              </> :null }

              {master === 1 ? (
                <div
                  onClick={() => settabtype(11)}
                  className={
                    tabtype === 11
                      ? "flex items-center px-3 py-2 rounded-md activebg"
                      : "flex items-center px-3 py-2 rounded-md"
                  }
                >
                  <img
                    width="17"
                    className="mr-2"
                    src="./images/icons/account-setting-icon.png"
                    alt=""
                  />
                  <div className="text-left">Organizations</div>
                </div>
              ) : null}

              <div
                onClick={() => settabtype(3)}
                className={
                  tabtype === 3
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/employee-type-icon.png"
                  alt=""
                />
                Employee
              </div>

              {/* <div className={tabtype==0 ? 'flex items-center px-3 py-2 rounded-md activebg' : 'flex items-center px-3 py-2 rounded-md'}> <img width="17" className='mr-2' src="./images/icons/employee-type-icon.png" alt="" /> Employee Type </div> */}

              {/* <div className={tabtype==0 ? 'flex items-center px-3 py-2 rounded-md activebg' : 'flex items-center px-3 py-2 rounded-md'}> <img width="17" className='mr-2' src="./images/icons/employee-type-icon.png" alt="" /> Employment Type</div> */}

              {master === 0 ? <>
              {/* <div
                onClick={() => settabtype(4)}
                className={
                  tabtype === 4
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/working-shift-icon.png"
                  alt=""
                />
                <div className="text-left"> Working Shifts</div>
              </div> */}

              <div
                onClick={() => settabtype(5)}
                className={
                  tabtype === 5
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/desktop-app-icon.png"
                  alt=""
                />
                <div className="text-left"> Desktop Apps</div>
              </div>

              </>: null}
              

              {/* <div
                onClick={() => settabtype(6)}
                className={
                  tabtype === 6
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/admin-notification-setting-icon.png"
                  alt=""
                />
                Notifications
              </div> */}
              {master === 1 ? <>
              <div
                onClick={() => settabtype(12)}
                className={
                  tabtype === 12
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/admin-notification-setting-icon.png"
                  alt=""
                />
                Job Applications
              </div>
              </>:null}

              {master === 0 ? <>
              
              

              {/* <div
                onClick={() => settabtype(7)}
                className={
                  tabtype === 7
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/alert-icon.png"
                  alt=""
                />
                Alerts
              </div> */}
             
              {/* <div
                onClick={() => settabtype(8)}
                className={
                  tabtype === 8
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/leave-type-icon.png"
                  alt=""
                />
                <div className="text-left"> Leave Types</div>
              </div> */}

              <div
                onClick={() => settabtype(9)}
                className={
                  tabtype === 9
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/holiday-icon.png"
                  alt=""
                />
                Holidays
              </div>
{/* 
              <div
                onClick={() => settabtype(10)}
                className={
                  tabtype === 10
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/account-setting-icon.png"
                  alt=""
                />
                <div className="text-left">Account Settings</div>
              </div> */}
              </> :null}
              
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:col-span-9 2xl:col-span-9">
          {tabtype === 0 ? <OrgDetails /> : null}
          {tabtype === 1 ? <Departments /> : null}
          {/* {tabtype === 2 ? <Designations /> : null} */}
          {tabtype === 3 ? <Employee /> : null}
          {tabtype === 4 ? <WorkingShifts /> : null}
          {tabtype === 5 ? <DesktopApps /> : null}
          {tabtype === 6 ? <Notifications /> : null}
          {tabtype === 7 ? <Alert /> : null}
          {tabtype === 8 ? <LeaveType /> : null}
          {tabtype === 9 ? <Holidays /> : null}
          {tabtype === 10 ? <AccountSettings /> : null}
          {tabtype === 11 ? <Masteradmin /> : null}
          {tabtype === 12 ? <JobApplication/> : null}
        </div>
      </div>
    </>
  );
};

export default Profile;
