import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import HashLoader from "react-spinners/HashLoader";
import swal from "sweetalert";

const SummaryApplications = () => {
  let [loading, setLoading] = useState(false);
  const [appdata, setappdata] = useState([]);

  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

    getAppData(
      format(new Date(firstDay), "yyyy-MM-dd"),
      format(new Date(), "yyyy-MM-dd"),
      ""
    );
  }, []);

  const getAppData = async (date, ldate, username) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-AppData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        ldate,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master:JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setappdata(res.data);
        } else {
          setappdata([{ appname: "No data" }]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  function AppName(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <div style={{ display: "Flex", padding: "8px" }}>
        <img src={rowData.icon} alt="" className="w-25" />{" "}
        <span className="mt-2 ml-2">{rowData.appname}</span>
      </div>
    );
  }

  function AppTime(props) {
    const rowData = props.cell._cell.row.data;
    var timeString;
    if (rowData.time !== undefined) {
      var date = new Date(0);
      date.setSeconds(rowData.time);
      timeString = date && date.toISOString().substring(11, 19);
    } else {
      timeString = "";
    }
    return <>{timeString}</>;
  }
  function AppProductive(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.is_productive === "Productive") {
      return (
        <>
          <div
            className="p-1"
            style={{ width: "215px", textDecoration: "underline" }}
          >
            <div onClick={() => changeStatus(0, rowData.icon_id)}>
              Productive
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className="p-1"
            style={{ width: "215px", textDecoration: "underline" }}
          >
            <div onClick={() => changeStatus(1, rowData.icon_id)}>
              Unproductive
            </div>
          </div>
        </>
      );
    }
  }

  const changeStatus = (status, id) => {
    swal({
      title: "Status",
      text: `Are you sure you want to change productive status?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `change-productive-status/`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            status,
            id,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            getAppData(
              format(new Date(firstDay), "yyyy-MM-dd"),
              format(new Date(), "yyyy-MM-dd"),
              ""
            );
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  const columns = [
    {
      title: "Application Name",
      field: "appname",
      formatter: reactFormatter(<AppName />),
    },
    {
      title: "Usage Duration",
      field: "time",
      formatter: reactFormatter(<AppTime />),
      width: 200,
    },
    // {
    //   title: "Productive",
    //   field: "is_productive",
    //   formatter: reactFormatter(<AppProductive />),
    //   width: 300,
    // },
  ];

  const options = {
    pagination: "local",
    paginationSize: 10,
  };

  return (
    <>
      <div className="intro-y grid grid-cols-12 gap-5 mt-4">
        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          {loading === true ? (
            <div className="mt-5 ">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : appdata && appdata.length > 0 ? (
            <ReactTabulator
              columns={columns}
              data={appdata}
              options={options}
              className="table-report table-report--tabulator"
            />
          ) : (
            "No Data"
          )}
        </div>
      </div>
    </>
  );
};

export default SummaryApplications;
