import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar, FaCamera, FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { HashLoader } from "react-spinners";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    border: "1px solid rgb(187, 181, 181)",
    borderRadius: "14px",
    overflowX: "hidden",
    overflowY: "visible",
    width: "1000px",
  },
};

const Activity = () => {
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [imgsrc, setimgsrc] = useState("");
  const [isopen, setIsOpen] = useState(null);
  const [date, setdate] = useState(new Date());
  const params = useParams();

  useEffect(() => {
    getData("");
  }, []);

  const handledate = (e) => {
    let date;
    if (e !== null) {
      setdate(e);
      date = format(new Date(e), "yyyy-MM-dd");
    } else {
      date = format(new Date(), "yyyy-MM-dd");
    }
    getData(date);
  };

  const columns = [
    { title: "Mouse Click", field: "mouseclicked" },
    { title: "Mouse Scroll", field: "mousescroll", width: 200 },
    { title: "Keyboard Clicks", field: "keyboardclicked" },
    { title: "Duration", field: "duration" },
    {
      title: "Screenshot",
      field: "screenshot",
      formatter: reactFormatter(<StatusVal />),
    },
    { title: "Date Time", field: "datetime" },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.screenshot !== "" ? (
          <FaCamera style={{ fontSize: "15px" }} />
        ) : null}
      </>
    );
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (date) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-activity/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
        date,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
        } else {
          setattdata([
            {
              mouseclicked: "No data Available",
              mousescroll: "",
              keyboardclicked: "",
              duration: "",
              screenshot: "",
              datetime: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const rowClick = (e, row) => {
    const rowData = row._row.data;

    setimgsrc(
      `https://d1qzgglzc5sraa.cloudfront.net/screenshot/${rowData.username}/${rowData.date}/${rowData.screenshot}`
    );
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Activity</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div
          className="overflow-x-auto scrollbar-hidden"
          style={{ minHeight: "700px" }}
        >
          <div className="h-auto">
            <div className="text-left">
              <label>Select Date</label> <br></br>
              <DatePicker
                clearIcon
                calendarIcon={
                  <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                }
                onChange={handledate}
                value={date}
              />
            </div>
          </div>
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              events={{
                rowClick: rowClick,
              }}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>

      <Modal isOpen={isopen} style={customStyles} contentLabel="">
        <div
          className="row"
          style={{
            marginRight: "10px",
            marginTop: "0px",
            paddingBottom: "5px",
            marginLeft: "3px",
          }}
        >
          <div class="flex items-center">
            <h2 class="font-medium text-base mr-auto">Preview</h2>
            <button
              class="btn btn-outline-secondary hidden sm:flex"
              onClick={() => closeModal()}
            >
              <FaTimes />
            </button>
          </div>
        </div>

        <div
          className="wrapper-grey pt-1 overflow-auto"
          style={{ marginRight: "10px" }}
        >
          <img src={imgsrc} className="img-fluid" alt="Preview Img" />
        </div>
      </Modal>
    </>
  );
};

export default Activity;
