import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";

const Attendance = () => {
  const loadingContext = useLoadingContext();

  const [attdata, setattdata] = useState(null);
  let [loading, setLoading] = useState(false);

  const [bkpdata, setbkpdata] = useState([]);

  useEffect(() => {
    getData();
   
  }, []);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getUserDetails/all`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 1,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let attarr = [];
          if (res.attdata.length > 0) {
            res.attdata.forEach((element) => {
              // console.log(element);
              let date1 = new Date(
                format(new Date(element.date), "yyyy-MM-dd") +
                  " " +
                  element.start_time
              );
              let date2 = new Date(
                format(new Date(element.date), "yyyy-MM-dd") +
                  " " +
                  element.end_time
              );
              let diff = date2.getTime() - date1.getTime();
              let msec = diff;
              let hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;

              let mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              let ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              let mmm = mm >= 10 ? mm : "0" + mm;
              let hhh = hh >= 10 ? hh : "0" + hh;
              let sss = ss >= 10 ? ss : "0" + ss;



              let data = {
                uuid: element.uuid,
                name: element.name,
                user_id: element.username,
                date: format(new Date(element.date), "yyyy-MM-dd"),
                in: element.start_time,
                out: element.end_time !== "00:00:00" ? element.end_time : "-",
                duration:
                  element.end_time !== "00:00:00"
                    ? hhh + ":" + mmm + ":" + sss
                    : "-",
                details: "More Details",
              };
              // console.log(data);
              attarr.push(data);
            });
          }

          setattdata(attarr);
          setbkpdata(attarr);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;
    // console.log(rowData);

    if (rowData.employee !== "") {
      // console.log(rowData);
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.name}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const columns = [
    {
      title: "Name",
      field: "name",
      formatter: reactFormatter(<DetailVal />),
      width: 250,
    },
    { title: "Date", field: "date", width: 150 },
    { title: "In", field: "in" },
    { title: "Out", field: "out" },
    { title: "Duration", field: "duration" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 10,
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      setattdata(bkpdata);
    } else {
      let matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });
      if (matches.length > 0) {
        setattdata(matches);
      } else {
        setattdata(bkpdata);
      }
    }
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Attandance</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form id="tabulator-html-filter-form" className="xl:flex sm:mr-auto">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                onChange={filterdata}
                placeholder="Search..."
              />
            </div>
          </form>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Attendance;
