import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { FaFileCsv } from "react-icons/fa";
import { ReactTabulator } from "react-tabulator";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";

const Summary = () => {
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [csvData, setcsvData] = useState([]);
  const [columns, setcolumn] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-employee-leavebalance`, {
      method: "POST",
      headers: myHeaders,
      uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
          let columns = [
            { title: "Employee", field: "Name" },
            { title: "Username", field: "Username" },
          ];
          res.type.forEach((element) => {
            if (element.name === "PL") return;
            columns.push({
              title: element.name + "(" + element.no + ")",
              field: element.name,
            });
          });

          setcolumn(columns);
          setcsvData([...res.data]);
        } else {
          let columns = [
            { title: "No data", field: "date" },
          ];
          setcolumn(columns);

          setattdata([
            {
              date: "No data Available",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">
          <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
              All Employee Leave Balance
            </h2>
            <div className="ml-auto flex items-center text-primary">
              <CSVLink
                filename={"All-Employees-Leave-Balance.csv"}
                className="ml-3 mr-3"
                data={csvData}
              >
                <FaFileCsv
                  style={{ color: "rgb(30 64 175)", fontSize: "25px" }}
                />
              </CSVLink>
            </div>
          </div>
          {loading === true ? (
            <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
              <HashLoader color="#5755d9" size={30} />
            </div>
          ) : (
            <div className="intro-y">
              <div className="overflow-x-auto scrollbar-hidden">
                {attdata ? (
                  <ReactTabulator
                    columns={columns}
                    data={attdata}
                    options={options}
                    className="mt-5 table-report table-report--tabulator"
                  />
                ) : (
                  <div className="text-danger text-left mt-5 mb-5">
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Summary;
