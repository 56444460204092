import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import SummaryChart from "./SummaryChart";
import { FaCalendar } from "react-icons/fa";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import HashLoader from "react-spinners/HashLoader";
import { BsGraphUp, BsGraphDown, BsBriefcase } from "react-icons/bs";
import { useLoadingContext } from "react-router-loading";
import Select from "react-select";
import reload from '../../reload.png'


const Summary = () => {
  const loadingContext = useLoadingContext();
  const [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [idledata, setidledata] = useState(null);
  const [countdata, setcountdata] = useState([]);
  const [chart1, setchart1] = useState([]);
  const [chart2, setchart2] = useState([]);
  const [chart3, setchart3] = useState([]);
  const [username, setusername] = useState("");
  const [currdate] = useState(new Date());
  const [orgval, setorgval] = React.useState([]);
  const [value, onChange] = useState([
    new Date(currdate.getFullYear(), currdate.getMonth(), 1),
    new Date(),
  ]);
  const [userdata, setuserdata] = useState([]);
  const [alluser, setalluser] = useState(null);
  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );

  const [orgs, setorgs] = React.useState([]);
  const [master, ] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [type, ] = React.useState(JSON.parse(localStorage.getItem("isAuth")).type);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);

  useEffect(() => {
    if(master===1){
      getOrganizations();
    }
    if(type !== 2) {
      getUserData();
    }
    setorgval({value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name});

    if (isAuth.role === 2) {
      setusername(isAuth.username);
      getData(
        isAuth.username,
        format(new Date(value[0]), "yyyy-MM-dd"),
        format(new Date(value[1]), "yyyy-MM-dd")
      );
    } else {
      getData(
        username,
        format(new Date(value[0]), "yyyy-MM-dd"),
        format(new Date(value[1]), "yyyy-MM-dd")
      );
    }

    const loading = async () => {
      loadingContext.done();
    };

    loading();
  }, []);

  const reloadFun = ()=> {
    if (isAuth.role === 2) {
      setusername(isAuth.username);
      getData(
        isAuth.username,
        format(new Date(value[0]), "yyyy-MM-dd"),
        format(new Date(value[1]), "yyyy-MM-dd")
      );
    } else {
      getData(
        username,
        format(new Date(value[0]), "yyyy-MM-dd"),
        format(new Date(value[1]), "yyyy-MM-dd")
      );
    }

  }


  const columns = [
    { title: "Date", field: "date", width: "30%" },
    { title: "Working Time", field: "working", width: "30%" },
    { title: "Idle Time", field: "idletime", width: "30%" },
    { title: "Productive Time", field: "prod", width: "30%" },
    {
      title: "Productive %",
      field: "prodper",
      width: "60%",
      formatter: reactFormatter(<AppUsage />),
    },
  ];
  
  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.employee}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }
  
  const idlecolumns = [
    { title: "Employee", field: "employee", width: "50%" ,formatter: reactFormatter(<DetailVal />)},
    { title: "Unproductive Time", field: "idletime", width: "50%" },
  ];

  const getOrganizations = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders    
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    if(isAuth.role === 0) {
      getUserData(org);
    }

  }

  function AppUsage(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.prodper !== undefined) {
      return (
        <>
          <div
            id="progressbar-height"
            className="p-1"
            style={{ width: "215px" }}
          >
            <div className="intro-y grid grid-cols-12 gap-5 mt-2">
              <div className="col-span-12 lg:col-span-11 2xl:col-span-10">
                <div className="preview">
                  <div className="progress h-3 mb-1 mt-2">
                    <div
                      className="progress-bar"
                      style={{ width: rowData.prodper + "%" }}
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >{rowData.prodper}%</div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>
          </div>
        </>
      );
    } else {
      return "";
    }
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (username, date, ldate, org="") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-datewise-productivity`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username,
        date,
        ldate,
        // company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        company_uuid: org!=='' ? org : selectedorg,
        master:JSON.parse(localStorage.getItem("isAuth")).master,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        department:JSON.parse(localStorage.getItem("isAuth")).department,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);

          setidledata(res.idlearr);
          setcountdata(res.actinfo);
          setchart1(res.totalworkingchart);
          setchart2(res.totalidlechart);
          setchart3(res.totalprodchart);
        } else {
          setattdata([
            {
              date: "No data Available",
            },
          ]);
          setcountdata([]);
          setidledata([
            {
              employee: "No data Available",
            },
          ]);
          setchart1([]);
          setchart2([]);
          setchart3([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handledate = (e) => {
    onChange(e);
    getData(
      username,
      format(new Date(e[0]), "yyyy-MM-dd"),
      format(new Date(e[1]), "yyyy-MM-dd")
    );
  };

  const getusername = (e) => {
    setusername(e.value);
    getData(
      e.value,
      format(new Date(value[0]), "yyyy-MM-dd"),
      format(new Date(value[1]), "yyyy-MM-dd")
    );
  };

  const getUserData = async (org="") => {
    // setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type:1,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org!=='' ? org : selectedorg,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        department:JSON.parse(localStorage.getItem("isAuth")).department,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setuserdata(res.data);
          let data4 = res.alluser ? res.alluser : [];
          setalluser(data4);
        }
      })
      .catch((err) => console.log(err));
  };

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return !arr2.find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };

  const filterByReference2 = (arr1, arr2) => {
    let res = [];
    res = arr1.filter((el) => {
      return arr2.find((element) => {
        return Number(element.username) === Number(el.username);
      });
    });
    return res;
  };
  let filterarr = filterByReference(userdata, alluser);
  let filterarr2 = filterByReference2(userdata, alluser);

  if (filterarr2 && filterarr2.length > 0) {
    for (let i = 0; i < filterarr2.length; i++) {
      filterarr2[i].active2 = true;
    }
  }

  let finalarr3 = filterarr2.concat(filterarr);

  let data = [{ value: "", label: "Select All", active: "" }];
  for (let i = 0; i < finalarr3.length; i++) {
    data.push({
      value: finalarr3[i].username,
      label: finalarr3[i].name,
      active: finalarr3[i].active2 ? "🟢" : "🔴",
    });
  }

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">
          <div className="intro-y flex flex-col md:flex-row items-center mt-2">
            <h2 className="text-lg font-medium mr-auto">Desktop Activity</h2>
            <div className="intro-y block sm:ml-auto sm:mt-0">
              <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-1 mt-1">
              <div className="flex xl:ml-5">
        
            {master === 1 &&
              <Select
                value={orgval}
                className="basic-single mr-2"
                onChange={(e) => {
                  getorg(e);
                }}
                options={orgs}
                // getOptionLabel={(option) =>
                //   ` ${option.active}   ${option.label}`
                // }
                styles={{
                  option: (base) => ({
                    ...base,
                    // height: "100%",
                    display: "flex",
                    textAlign: "left",
                  }),
                }}
              />
            }
            
              {type!==2 && 
                  <>
                    <div className="lg:ml-2  ">
                      <Select
                        defaultValue={data[0]}
                        className="basic-single text-center mr-2"
                        onChange={(e) => {
                          getusername(e);
                        }}
                        options={data}
                        getOptionLabel={(option) =>
                          ` ${option.active}   ${option.label}`
                        }
                        styles={{
                          option: (base) => ({
                            ...base,
                            height: "100%",
                            display: "flex",
                          }),
                        }}
                      />
                    </div>
                  </>
               }
                </div>

                <DateRangePicker
                  clearIcon=""
                  calendarIcon={
                    <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                  }
                  className="form-control"
                  onChange={handledate}
                  value={value}
                />

                <button className="ml-1 btn btn-default">
                  <img width={40} onClick={reloadFun} src={reload} alt="" srcset="" />
                 </button>


              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 mt-8 ">
              <div className="">
                {loading ? (
                  <div className="text-center m-5 p-5">
                    <HashLoader
                      color="#5755d9"
                      size={30}
                      style={{ position: "absolute", right: "42%" }}
                    />
                  </div>
                ) : (
                  <div className="lg:flex">
                    <div className="box boxaddon" >
                      <div
                        className="text-primary dark:text-slate-200 text-md xl:text-md font-medium flex  px-5"
                        // style={{ justifyContent: "" ,width:"100%" }}
                      >
                        <span>Total Working</span>
                        {/* <span>
                          <BsBriefcase />
                        </span> */}
                      </div>
                      <div >
                        {countdata && countdata.totalworking
                          ? countdata.totalworking
                          : "00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                    <div className={"box boxaddon"}>
                      <div
                        className="text-primary dark:text-slate-200 text-md xl:text-md font-medium px-4 flex flex-col lg:flex-row lg:items-center flex "
                        // style={{
                        //   justifyContent: "space-between",
                        //   color: "#5bdba1",
                        //   width:"100%"
                        // }}
                      >
                        <span>Total Productive</span>
                        {/* <span className="justify-end">
                          <BsGraphUp />
                        </span> */}
                      </div>
                      <div className="mt-0.5 text-slate-500">
                        {countdata && countdata.totalprod
                          ? countdata.totalprod
                          : "00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                    <div className={"box boxaddon"}>
                      <div
                        className="text-primary dark:text-slate-200 text-md xl:text-md font-medium px-5 flex flex-col lg:flex-row lg:items-center flex"
                        // style={{
                        //   justifyContent: "space-between",
                        //   color: "red",
                        //   width:"100%"
                        // }}
                      >
                        <span>Total Idle</span>
                        {/* <span>
                          <BsGraphDown />
                        </span> */}
                      </div>
                      <div className="mt-0.5 text-slate-500">
                        {countdata && countdata.totalidle
                          ? countdata.totalidle
                          : "00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                    <div className={"box boxaddon"}>
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Avg. Working
                      </div>
                      <div className="mt-0.5 text-slate-500">
                        {countdata && countdata.avgtotalworking
                          ? countdata.avgtotalworking
                          : "00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                    <div className={"box boxaddon"}>
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Avg. Productive
                      </div>
                      <div className="mt-0.5 text-slate-500">
                        {countdata && countdata.avgtotalprod
                          ? countdata.avgtotalprod
                          : " 00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                    <div className={"box boxaddon"}>
                      <div className="text-primary dark:text-slate-200 text-md xl:text-md font-medium">
                        Avg. Idle
                      </div>
                      <div className="mt-0.5 text-slate-500">
                        {countdata && countdata.avgtotalidle
                          ? countdata.avgtotalidle
                          : " 00:00:00 "} hh:mm:ss
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                {isAuth.role === 0 && (
                  <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
                    <div className="intro-y box p-5 text-left">
                      <h2 style={{ fontSize: "15px", fontWeight: 600 }}>
                        Team's Working Time
                      </h2>

                      <div className="overflow-x-auto scrollbar-hidden">
                        {loading ? (
                          <div className="text-center m-5 p-5">
                            <HashLoader
                              color="#5755d9"
                              size={30}
                              style={{ position: "absolute", right: "50%" }}
                            />
                          </div>
                        ) : chart1 && chart1.length > 0 ? (
                          <SummaryChart
                            attdata={chart1}
                            type="Working Time"
                          ></SummaryChart>
                        ) : (
                          "No data available"
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
                  <div className="intro-y box p-5 text-left">
                    <h2 style={{ fontSize: "15px", fontWeight: 600 }}>
                      Idle Time
                    </h2>

                    <div className="overflow-x-auto scrollbar-hidden">
                      {loading ? (
                        <div className="text-center m-5 p-5">
                          <HashLoader
                            color="#5755d9"
                            size={30}
                            style={{ position: "absolute", right: "50%" }}
                          />
                        </div>
                      ) : chart2 && chart2.length > 0 ? (
                        <SummaryChart
                          attdata={chart2}
                          type="Idle Time"
                        ></SummaryChart>
                      ) : (
                        "No data available"
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
                  <div className="intro-y box p-5 text-left">
                    <h2 style={{ fontSize: "15px", fontWeight: 600 }}>
                      Productive Time
                    </h2>
                    <div className="overflow-x-auto scrollbar-hidden">
                      {loading ? (
                        <div className="text-center m-5 p-5">
                          <HashLoader
                            color="#5755d9"
                            size={30}
                            style={{ position: "absolute", right: "50%" }}
                          />
                        </div>
                      ) : chart3 && chart3.length > 0 ? (
                        <SummaryChart
                          attdata={chart3}
                          type="Productive Time"
                        ></SummaryChart>
                      ) : (
                        "No data available"
                      )}
                    </div>
                  </div>
                </div>
                {isAuth.role === 0 && (
                  <div className="col-span-12 sm:col-span-12 xl:col-span-6 intro-y">
                    <div className="intro-y box p-5 text-left">
                      <h2 style={{ fontSize: "15px", fontWeight: 600 }}>
                        Employee with most Unproductive Time
                      </h2>
                      <div className="intro-y box">
                        <div className="overflow-x-auto scrollbar-hidden">
                          {loading ? (
                            <div className="text-center m-5 p-5">
                              <HashLoader
                                color="#5755d9"
                                size={30}
                                style={{ position: "absolute", right: "50%" }}
                              />
                            </div>
                          ) : (
                            <ReactTabulator
                            // style={{width:'100%'}}
                              columns={idlecolumns}
                              data={idledata}
                              options={options}
                              className="mt-5 table-report table-report--tabulator"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 sm:col-span-12 xl:col-span-12 intro-y">
                  <div className="intro-y box p-2 text-left">
                    <h2
                      style={{ fontSize: "15px", fontWeight: 600 }}
                      className="pt-4 pl-2"
                    >
                      Employee Productivity
                    </h2>
                    <div className="overflow-x-auto scrollbar-hidden">
                      {loading ? (
                        <div className="text-center m-5 p-5">
                          <HashLoader
                            color="#5755d9"
                            size={30}
                            style={{ position: "absolute", right: "50%" }}
                          />
                        </div>
                      ) : (
                        <ReactTabulator
                          columns={columns}
                          data={attdata}
                          options={options}
                          className="mt-5 table-report table-report--tabulator"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
