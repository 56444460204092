export const TABTYPE = 'TABTYPE'; 
export const GETSCREENSHOT = 'GETSCREENSHOT'; 
export const SET_SCREENSHOT = 'SET_SCREENSHOT'; 

export const GETATTANDANCE = 'GETATTANDANCE'; 
export const SET_ATTANDANCE = 'SET_ATTANDANCE'; 

export const GETLEAVES = 'GETLEAVES'; 
export const SETLEAVES = 'SETLEAVES'; 

export const GETPRODUCTIVITY = 'GETPRODUCTIVITY'; 
export const SET_PRODUCTIVITY = 'SET_PRODUCTIVITY'; 

export const SET_STOPWATCH = 'SET_STOPWATCH'; 
export const GET_STOPWATCH = 'GET_STOPWATCH'; 

export const SHOW_LOADING = 'SHOW_LOADING'; 
export const HIDE_LOADING = 'HIDE_LOADING'; 

export const SWITCH_THEME = 'SWITCH_THEME';
export const GET_CURRENT_EMP = 'GET_CURRENT_EMP';