import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";

const OrgDetails = () => {
  const [data, setdata] = useState({
    name: "",
    accmanager: "",
    supportcontact: "",
    supportaccess: false,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);
  let [userlogindata, setuserlogindata] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllOrgdetails/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setdata({
            name: res.data[0].name,
            accmanager: res.data[0].account_manager,
            supportcontact: res.data[0].support_contact,
            supportaccess: res.data[0].support_access === 1 ? true : false,
          });
        } else {
          setdata({
            name: "",
            accmanager: "",
            supportcontact: "",
            supportaccess: false,
          });
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handlechange = (e) => {
    let value = e.target.value;
    if (e.target.name === "supportaccess") {
      value = e.target.checked;
    }
    setdata({ ...data, [e.target.name]: value });
  };

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const savedata = async () => {
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("name", data.name);
    formData.append("accmanager", data.accmanager);
    formData.append("supportcontact", data.supportcontact);
    formData.append("supportaccess", data.supportaccess);
    formData.append("company_uuid", userdata.companydata[0].uuid);

    try {
      const res = await axios.post(BASEURL + "save-orgdetails", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.status === 201) {
        userdata.companydata[0].name = data.name;
        userdata.companydata[0].support_access = data.supportaccess;
        userdata.companydata[0].orglogo = res.data.logo;

        // localStorage.setItem("isAuth", JSON.stringify(userdata));
        setmsg(res.data.message);
        setTimeout(() => {
          setmsg("");
        }, 3000);
      }
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Organization Details</h2>
      </div>
      {loading ? (
        <div className="text-center m-5 p-5">
          <HashLoader
            color="#5755d9"
            size={30}
            style={{ position: "absolute", right: "50%" }}
          />
        </div>
      ) : (
        <div className="p-5">
          <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-6">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Organization Name*
                    </label>
                    <input
                      id="update-profile-form-1"
                      type="text"
                      value={data.name}
                      name="name"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>

                  <div className="pt-2 pb-2">
                    <label htmlFor="ologo" className="form-label">
                      Organization Logo
                    </label>
                    <input
                      type="file"
                      className="form-control py-2 border"
                      onChange={handleFileInputChange}
                    />
                    {userlogindata.companydata[0].orglogo !== "" ? (
                      <img
                        width={"150px"}
                        className="mt-2"
                        src={
                          BASEURL +
                          `uploads/orglogo/${userlogindata.companydata[0].orglogo}`
                        }
                        alt=""
                        srcSet=""
                      />
                    ) : null}
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Account Manager
                    </label>
                    <input
                      id="update-profile-form-6"
                      type="text"
                      value={data.accmanager}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-6">
                  <div className="mt-3 2xl:mt-0">
                    <label
                      htmlFor="update-profile-form-3"
                      className="form-label"
                    >
                      Support Contact
                    </label>
                    <input
                      id="update-profile-form-6"
                      type="text"
                      value={data.supportcontact}
                      className="form-control"
                      readOnly
                    />
                  </div>
                  <div className="mt-3">
                    <label>Support Acccess</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="supportaccess"
                        onChange={handlechange}
                        checked={data.supportaccess === true}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-20 mt-3"
              >
                Save
              </button>
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrgDetails;
