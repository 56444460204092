import React,{useEffect,useState} from 'react'
import {BASEURL} from '../BASEURL';
import {  FaCalendar, FaFileCsv } from 'react-icons/fa';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { format } from 'date-fns'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { CSVLink, CSVDownload } from "react-csv";
import HashLoader from "react-spinners/HashLoader";

const Summary = () => {
    let [loading, setLoading] = useState(false);
    const [attdata, setattdata] = useState([]);
    const [username, setusername] = useState("");
    const [csvData, setcsvData] = useState([]);
    const [currdate, ] = useState(new Date());
    const [value, onChange] = useState([new Date(currdate.getFullYear(), currdate.getMonth(), 1), new Date()]);
    const [columns, setcolumn] = useState([]);
   
    useEffect(() => {
        getData(format(new Date(value[0]), 'yyyy-MM-dd'), format(new Date(value[1]), 'yyyy-MM-dd'));
    }, [])

    const options = {
           pagination: 'local',
        paginationSize: 50,
    };

    const getData= async(date, ldate)=>{
            setLoading(true);
            const myHeaders = new Headers();
            let userdata = JSON.parse(localStorage.getItem("isAuth")); 
            if(userdata!=null){
                let token = userdata.usertoken;
                myHeaders.append('Authorization', token); 
            }
            myHeaders.append('Content-Type', 'application/json');
            await fetch(BASEURL+`get-all-leaves`, {
                method:"POST",
                headers: myHeaders,
                body: JSON.stringify({
                    username:"", date,ldate,
                    uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
                })
                
            }).then(response => response.json()).then(res =>{
                if(res.status === 201){   
                    setattdata(res.data) 
                    let columns = [
                        { title: "Employee", field: "name" },
                        { title: "Leave Type", field: "type"},
                        { title: "Status", field: "status"},
                        { title: "Leave Date", field: "from"},                        
                        { title: 'No. of Days', field: 'noofdays'},                        
                        { title: 'Applied On', field: 'appliedon'},                        
                    ];
                  
                    setcolumn(columns);                                     
                    setcsvData([...res.reparr]);    
               
                 }
            }).catch(err => console.log(err));
           
            setLoading(false);
    }
     
    const handledate =(e) =>{
        onChange(e)   
        getData(format(new Date(e[0]), 'yyyy-MM-dd'), format(new Date(e[1]), 'yyyy-MM-dd'));
    }   


 
  return (
    <>
    <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">

        <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
                All Leaves
            </h2>
            <div className="ml-auto flex items-center text-primary">
                <DateRangePicker clearIcon="" calendarIcon={<FaCalendar style={{color:'rgb(30 64 175)'}}/>} className="form-control" onChange={handledate} value={value} />
                <CSVLink  filename={"All-Employees-Leaves.csv"} className='ml-3 mr-3' data={csvData}><FaFileCsv style={{color:'rgb(30 64 175)', fontSize:'25px'}}/></CSVLink>
            </div>   
        </div>
        { loading===true ?
        <div className='mt-5 ' style={{display: '-webkit-inline-box'}}>
        <HashLoader color='#5755d9' size={30} />
        </div>
        :
        <div className="intro-y">
                <div className="overflow-x-auto scrollbar-hidden">
              

                    {attdata && attdata.length>0 ?
                    <ReactTabulator
                        columns={columns}
                        data={attdata}
                        options={options}
                        className="mt-5 table-report table-report--tabulator"
                    /> 
                    :
                    <div className="text-danger text-left mt-5 mb-5">
                        No Data Available
                    </div>
                    }       
                </div>
            </div>   
        }             
        </div>       
    </div>  
    </>
  )
}

export default Summary