import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";
import { SITEURL, BASEURL } from "./BASEURL";
import { Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import { FaUserCircle, FaEye, FaSignInAlt } from "react-icons/fa";
import Select from "react-select";
import swal from "sweetalert";


const Employees = () => {
  const loadingContext = useLoadingContext();
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [userdata, setuserdata] = useState([]);
  const [bkpdata, setbkpdata] = useState([]);
  const [items, setitems] = useState([]);
  let [loading, setLoading] = useState(false);
  const [orgs, setorgs] = React.useState([]);
  const [master, ] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);

  useEffect(() => {
    setorgval({value: selectedorg, label: JSON.parse(localStorage.getItem("isAuth")).companydata[0].name});
    if(master === 1){
      getOrganizations();
    }
    getData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 30;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 30));
    
  }, [itemOffset]);

  
  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders    
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const getorg = async (org) => {
    setorgval(org);
    org = org.value
    setselectedorg(org);
    getData(org)
  }

  const getData = async (org="") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));

    if (userdata != null) {
      let token = userdata.usertoken;

      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", token);
    }
    // console.log(JSON.parse(localStorage.getItem("isAuth")));
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        role: 0,
        type:1,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        department:JSON.parse(localStorage.getItem("isAuth")).department,
        uuid: org!=='' ? org : selectedorg,
        // uuid: userdata.companydata[0].uuid, master:JSON.parse(localStorage.getItem("isAuth")).company_uuid
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setbkpdata(res.data);
          // console.log(res.data);
          setuserdata(res.data);
          let arr = [];
          let arr1 = [];
          for (const key in res.data) {
            arr.push(key);
            if (key <= 30) {
              arr1.push(key);
            }
          }
          setitems(arr);

          setCurrentItems(arr1);
          setPageCount(Math.ceil(arr.length / 30));
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 30) % items.length;

    setItemOffset(newOffset);
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setuserdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 30));
    } else {
      let matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 30) % matches.length;
      setItemOffset(newOffset);
      setuserdata(matches);
      const endOffset = itemOffset + 30;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 30));
    }
  };

  const signindata = async(data) => { 
    // console.log(data); return
    swal({
      title: "Sign In as "+data.name,
      text: `Are you sure you want to Sign In as ${data.name}?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
    // console.log(data.companyuuid); return
    // window.open(SITEURL, '_blank', 'incognito=yes');
    let authdata = JSON.parse(localStorage.getItem("isAuth"));
    // console.log(authdata); return

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
     fetch(BASEURL + `get-companydata/${data.company_uuid}`, {
      method: "GET",
      headers: myHeaders,      
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let obj = {
            status: 201,
            message: "Signin successful",
            usertoken: authdata.usertoken,
            id: data.user_id,
            uuid: data.uuid,
            username: data.username,
            name: data.name,
            department: data.department,
            avatar: data.avatar,
            role: data.type,
            timezone: "",
            dept: data.deptname,
            companydata:res.data,
            master: data.master,
            issigninbyadmin: 1,
            type: data.type,
          };
          // return
          localStorage.setItem("isAuth", JSON.stringify(obj));
          window.open(SITEURL,"_self");
          // 'incognito=yes'
        } else {
          swal({
            title: "Error",
            text: res.message,
            icon: "error",
            timer: 2000,
          });
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
 
  }
}); 

  }


  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <div className="hidden md:block mr-auto text-slate-500">
          <h2 className=" text-lg font-medium  text-left">All Users</h2>
            Showing {itemOffset + 1} to{" "}
            {userdata.length > itemOffset + 30
              ? itemOffset + 30
              : userdata.length}{" "}
            of {userdata.length} entries
          </div>
          {master === 1 &&
    <Select
      value={orgval}
      className="basic-single mr-2"
      onChange={(e) => {
        getorg(e);
      }}
      options={orgs}
      // getOptionLabel={(option) =>
      //   ` ${option.active}   ${option.label}`
      // }
      styles={{
        option: (base) => ({
          ...base,
          // height: "100%",
          display: "flex",
          textAlign: "left",
        }),
      }}
    />
  }
          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div className="w-56 relative text-slate-500">
              <input
                type="text"
                className="form-control w-56 box pr-10"
                onChange={(e) => filterdata(e)}
                placeholder="Search..."
              />
              <i
                className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                data-lucide="search"
              ></i>
            </div>
          </div>
        </div>

        {currentItems &&
          currentItems.map((item, i) => (
            <div className="intro-y col-span-12 md:col-span-4" key={i}>
              <div className="box">
              {/* <Link
                    to={"/employees/" + userdata[item].uuid}
                    // className="btn btn-outline-secondary py-1 px-2"
                  > */}
                <div className="flex flex-col lg:flex-row items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400" style={{borderRadius:"5px"}}>
                  <div
                    className="
                            "
                  >
                    <FaUserCircle
                      style={{ fontSize: "50px", color: "#1d45ab" }}
                    />
                  </div>
                  <div className="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                    <Link
                      to={"/employees/" + userdata[item].uuid}
                      className="font-medium"
                    >
                      {userdata[item].name} ({userdata && userdata[item].username})
                    </Link>
                    <div className="text-slate-500 text-xs mt-0.5" style={{color: "#afb0b3"}}>
                      {userdata[item].designation}
                    </div>
                  </div>
                  {/* <div class="text-success">DOJ: {format(new Date(userdata[item].date_of_joining), "yyyy-MM-dd")}</div> */}
                  <Link
                    to={"/employees/" + userdata[item].uuid}
                    className="btn btn-outline-default py-1 px-2"
                    title="View Profile"
                  >
                  <FaEye/>
                  </Link>
                  <div
                    onClick={() => signindata(userdata[item])}
                    title={'Sign In as '+userdata[item].name}
                    className="ml-2 btn btn-outline-default py-1 px-2"
                  >
                  <FaSignInAlt/>
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </div>
          ))}

        <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
          <nav className="w-full sm:w-auto sm:mr-auto">
            {!loading ? (
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-4">
                  <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={30}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    nextClassName="page-item"
                    previousClassName="page-item"
                    previousLabel={<>&laquo;</>}
                    nextLabel={<>&raquo;</>}
                  />
                </div>
                <div className="col-md-4"></div>
              </div>
            ) : (
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            )}
          </nav>
        </div>
      </div>
    </>
  );
};

export default Employees;
