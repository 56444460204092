import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";

const WorkingShifts = () => {
  const [shiftdata, setshiftdata] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [data, setdata] = useState({
    id: "",
    alert: "",
    is_enable: false,
    for_manager: false,
    max_alert: 0,
    alert_interval: 0,
    alert_repeat: 0,
  });

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);

  useEffect(() => {
    getShiftData();
  }, []);

  const getShiftData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllAlerts/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        type: 1,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setshiftdata(res.data);
          setbkpdata(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handlechange = (e) => {
    let value = e.target.value;
    if (e.target.name === "is_enable") {
      value = e.target.checked;
    }
    if (e.target.name === "for_manager") {
      value = e.target.checked;
    }
    setdata({ ...data, [e.target.name]: value });
  };

  const savedata = async () => {
    if (data.alert_interval === "" || data.alert_repeat === "") {
      swal({
        title: "Error",
        text: "Please Enter value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `save-alertdata/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        data,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmsg(res.message);
          getShiftData();
          setTimeout(() => {
            setdata({
              alert: "",
              is_enable: false,
              for_manager: false,
              max_alert: 0,
              alert_interval: 0,
              alert_repeat: 0,
            });
            setmsg("");
            setisadd(!isadd);
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const editdata = (data) => {
    setisadd(true);
    let is_enable;
    let for_manager;
    if (data.is_enable === 1) {
      is_enable = true;
    } else {
      is_enable = false;
    }
    if (data.for_manager === 1) {
      for_manager = true;
    }
    if (data.for_manager === 0) {
      for_manager = false;
    }

    setdata({
      id: data.id,
      alert: data.alert,
      is_enable: is_enable,
      for_manager: for_manager,
      max_alert: data.max_alert,
      alert_interval: data.alert_interval,
      alert_repeat: data.alert_repeat,
    });
  };

  const adddata = (data) => {
    setisadd(!isadd);
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      setshiftdata(bkpdata);
    } else {
      var matches = shiftdata.filter(function (s) {
        return s.alert.toLowerCase().includes(`${event.target.value}`);
      });
      if (matches.length > 0) {
        setshiftdata(matches);
      } else {
        setshiftdata(bkpdata);
      }
    }
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Alert</h2>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-7 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Alert Type
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.alert}
                      type="text"
                      name="max_alert"
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-2 mt-3">
                  <div className="mt-3">
                    <label>Enable{data.productivity_enabled}</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="is_enable"
                        onChange={handlechange}
                        checked={data.is_enable === true}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-2 mt-3"></div>

                <div className="col-span-12 2xl:col-span-8 mt-3">
                  <div className="mt-3">
                    <label>Manager</label>
                    <div className="form-switch mt-2">
                      <input
                        type="checkbox"
                        name="for_manager"
                        onChange={handlechange}
                        checked={data.for_manager === true}
                        className="form-check-input"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Max Alert
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.max_alert}
                      type="text"
                      name="max_alert"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Repeat(Sec.)*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.alert_interval}
                      type="number"
                      min={0}
                      name="alert_interval"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-6 mt-3">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Delay(Min.)*
                    </label>
                    <input
                      id="update-profile-form-1"
                      value={data.alert_repeat}
                      type="number"
                      min={0}
                      name="alert_repeat"
                      className="form-control"
                      onChange={handlechange}
                    />
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-20 mt-3"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => adddata()}
                className="btn btn-danger w-20 mt-3 ml-2"
              >
                Cancel
              </button>
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">#</th>
                  <th className="whitespace-nowrap">Alert</th>
                  <th className="whitespace-nowrap">Enable</th>
                  <th className="whitespace-nowrap">Manager</th>
                  <th className="whitespace-nowrap">Interval(SEC.)</th>
                  <th className="whitespace-nowrap">Repeat(SEC.)</th>
                  <th className="whitespace-nowrap">Created</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
                {shiftdata && shiftdata.length > 0 ? (
                  shiftdata.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.id}</td>
                        <td>{item.alert}</td>
                        <td>{item.is_enable === 1 ? "Yes" : "No"}</td>
                        <td>{item.for_manager === 1 ? "Yes" : "No"}</td>
                        <td>{item.alert_interval}</td>
                        <td>{item.alert_repeat}</td>
                        <td>
                          {format(
                            new Date(item.created_at),
                            "yyyy-MM-dd hh:mm:ss"
                          )}
                        </td>
                        <td>
                          <div className="flex lg:justify-center items-center">
                            <div
                              className="edit flex items-center mr-3"
                              onClick={() => editdata(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="check-square"
                                data-lucide="check-square"
                                className="lucide lucide-check-square w-4 h-4 mr-1"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                              </svg>{" "}
                              Edit
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9}>No data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkingShifts;
