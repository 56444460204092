import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaFileCsv } from "react-icons/fa";
import { format } from "date-fns";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { CSVLink } from "react-csv";
import HashLoader from "react-spinners/HashLoader";
import { useLoadingContext } from "react-router-loading";
import { ReactTabulator, reactFormatter } from "react-tabulator";
const Summary = () => {
  const loadingContext = useLoadingContext();
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const username = "";
  const [csvData, setcsvData] = useState([]);
  const [currdate] = useState(new Date());
  const [value, onChange] = useState([
    new Date(currdate.getFullYear(), currdate.getMonth(), 1),
    new Date(),
  ]);
  const [columns, setcolumn] = useState([]);

  useEffect(() => {
    getData(
      username,
      format(new Date(value[0]), "yyyy-MM-dd"),
      format(new Date(value[1]), "yyyy-MM-dd")
    );
    
  }, []);

  const options = {
    pagination: "local",
    paginationSize: 10,
  };

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.name}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const getData = async (username, date, ldate) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-monthwise-attreport`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        username,
        date,
        ldate,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        let columns = [
          {
            title: "Employee",
            field: "name",
            formatter: reactFormatter(<DetailVal />),
            width: 220,
          },
          { title: "Username", field: "username", width: 120 },
        ];
        columns.push(
          { title: "Present", field: "present", width: 140 },
          { title: "Absent", field: "absent", width: 140 },
          { title: "Leaves", field: "leaves", width: 140 },
          { title: "Halfday", field: "halfdayleaves", width: 140 },
          { title: "Holidays", field: "holidays", width: 140 }
        );
        if (res.status === 201) {
          setattdata(res.data);

          res.datearr.forEach((element) => {
            columns.push({
              title: format(new Date(element), "dd E"),
              field: element,
              width: 140,
            });
          });

          setcsvData([...res.reparr]);
        } else {
          setattdata([
            {
              name: "No data Available",
            },
          ]);
        }
        setcolumn(columns);
      })
      .catch((err) => console.log(err));

    setLoading(false);
    loadingContext.done();
  };

  const handledate = (e) => {
    onChange(e);
    const savedata = getData(
      username,
      format(new Date(e[0]), "yyyy-MM-dd"),
      format(new Date(e[1]), "yyyy-MM-dd")
    );
    localStorage.setItem("checkdate", savedata);
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 2xl:col-span-12">
          <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
            <h2 className="text-lg font-medium mr-auto">
              Monthly Attendance Report
            </h2>
            <div className="ml-auto flex items-center text-primary">
              <DateRangePicker
                clearIcon=""
                calendarIcon={
                  <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                }
                className="form-control"
                onChange={handledate}
                value={value}
              />
              <CSVLink
                filename={"All-Employees-Monthly-Attendance.csv"}
                className="ml-3 mr-3"
                data={csvData}
              >
                <FaFileCsv
                  style={{ color: "rgb(30 64 175)", fontSize: "25px" }}
                />
              </CSVLink>
            </div>
          </div>
          {loading === true ? (
            <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
              <HashLoader color="#5755d9" size={30} />
            </div>
          ) : (
            <div className="intro-y">
              <div className="overflow-x-auto scrollbar-hidden">
                {attdata ? (
                  <ReactTabulator
                    columns={columns}
                    data={attdata}
                    options={options}
                    className="mt-5 table-report table-report--tabulator"
                  />
                ) : (
                  <div className="text-danger text-left mt-5 mb-5">
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Summary;
