import React,{ useEffect , useState } from "react";
import "./App.css";
import { connect } from 'react-redux'
import * as All from "./Export";
import Home from "./componunt/Home";
import { BrowserRouter as Router,Routes,Route, Navigate, useNavigate } from "react-router-dom";
// import { topbar } from "react-router-loading";
// import { Routes, Route } from "react-router-loading";
import { useSelector, useDispatch } from 'react-redux'
import { ToastProvider, useToasts } from 'react-toast-notifications';

import { 
	onSwitchTheme,
} from './redux/actions/Action';

// topbar.config({
//   barThickness: 4,
//   barColors: {
//     0: "rgb(255, 99, 71)",
//     0.3: "rgb(255, 99, 71)",
//     1.0: "rgb(255, 99, 71)",
//   },
//   shadowBlur: 2,
//   shadowColor: "red",
//   className: "topbar",
// });

const Routing = (props) => {
  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );

  const isdarmode=(mode)=>{
    setisdark(mode)
  
  }
  const [, setisdark] = useState(props.isdark);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  // useEffect(() => {
  //   setisdark(props.isdark)
  // }, [isdark]);
  // console.log(props.isdark);  
  // jo ahi update thase
  

  const logtimer = () => {
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('isAuth');
    if (setupTime != null) {
            addToast('Session expired. Please login again', { appearance: 'warning' });         
            localStorage.clear()
            localStorage.removeItem('isAuth');
            localStorage.removeItem('isAuth_bkp');
            setTimeout(() => {
              navigate('/login',{replace:true})
              navigate(0)  
            }, 3000);
    }
  }

  setInterval(logtimer, 1000 * 60 * 60);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={isAuth.role === 0 || isAuth.role === 1 ? <Home  toggle={props.isdark} /> : <All.HomeEmpliyee />}
          loading
        />

        <Route exact path="/job-application" element={<All.JobApplication />} loading />

        <Route exact path="/employees" element={<All.Employees />} loading />
        
        <Route
          exact
          path="/employees/:uuid"
          element={<All.EmployeesDetails />}
        />
        <Route
          exact
          path="/punch-ticket"
          // element={<All.EmployeeTicket />}
          element={isAuth.role === 2 ? <All.EmployeeTicket /> : <All.AdminTicket />}
          loading
        />
        <Route
          exact
          path="/leaves/"
          element={isAuth.role === 0 ? <All.Leaves /> : <All.LeavesEmployee />}
          loading
        />
        <Route
          exact
          path="/attendance/attendance-details"
          element={
            isAuth.role === 0 ? <All.Attendance /> : <All.AttendanceEmployee />
          }
          loading
        />
        <Route
          exact
          path="/attendance/monthly-records"
          element={
            isAuth.role === 0 ? (
              <All.MonthlyAttendancedata />
            ) : (
              <All.MonthlyAttendanceEmployee />
            )
          }
          loading
        />
        <Route exact path="/feeds" element={<All.Feeds />} loading />
        <Route
          exact
          path="/employee-activity"
          element={<All.EmployeeActivity />}
          loading
        />

        <Route
          exact
          path="/insights/summary"
          element={<All.Summary />}
          loading
        />
        <Route
          exact
          path="/insights/timeline"
          element={<All.Timeline />}
          loading
        />
        <Route
          exact
          path="/insights/productivity"
          element={<All.TeamProductivity />}
          loading
        />
        <Route
          exact
          path="/insights/screenshots"
          element={<All.Screenshots />}
          loading
        />
        <Route
          exact
          path="/insights/applications"
          element={<All.Applications />}
          loading
        />

        <Route
          exact
          path="/dashboard/summary"
          element={<All.DashboardSummary />}
          loading
        />
        <Route
          exact
          path="/dashboard/app-usage"
          element={<All.AppUsage />}
          loading
        />
        <Route
          exact
          path="/dashboard/desktop-activity"
          element={<All.DesktopActivity />}
          loading
        />

        <Route exact path="/report" element={<All.Template />} loading />
        <Route
          exact
          path="/report/attendance"
          element={<All.AttendanceReport />}
        />
        <Route
          exact
          path="/report/monthly-attendance"
          element={<All.MonthlyAttendance />}
        />
        <Route
          exact
          path="/report/attendance-regularization"
          element={<All.AttendanceRegularization />}
        />
        <Route exact path="/report/late-mark" element={<All.LateMark />} />
        <Route exact path="/report/leave" element={<All.Leave />} />
        <Route
          exact
          path="/report/employee-leave-balance"
          element={<All.EmployeeLeaveBalance />}
        />
        <Route
          exact
          path="/report/app-usage"
          element={<All.AppUsageReport />}
        />
        <Route
          exact
          path="/report/app-usage-by-day"
          element={<All.AppUsagebyDay />}
        />
        <Route
          exact
          path="/report/daily-productivity"
          element={<All.DailyProductivity />}
        />

        <Route exact path="/admin" element={<All.Admin />} loading />
        <Route exact path="/profile" element={<All.Employees />} />

        <Route exact path="/404" element={<All.PageNotFound />} />
        <Route exact path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
};

function App() {
  const [isAuth, setIsAuth] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );
  const [isdark, setisdark] = useState(0);

  if (isAuth === null) {
    document.body.classList.add("login");
  }

  const dispatch = useDispatch();
  // console.log(window.location.pathname);

  const isLogin = (status) => {
    setIsAuth(status);
    document.body.classList.remove("login");
  };
  const isLogout = () => {
    setIsAuth(null);
    document.body.classList.add("login");
  };
  const isDarkTheme = (e) => {
    let value = e.target.checked;
    var v = document.getElementById("html");
    if(value==true){
      v.classList.add("dark");
      setisdark(1)
      dispatch(onSwitchTheme(1))

    }else{
      v.classList.remove("dark");
      setisdark(0)
      dispatch(onSwitchTheme(0))
    }
  };

  return (
    <ToastProvider>

    <Router>
    <div data-url="https://rubick.left4code.com/page/side-menu/dark/dashboard-overview-1" className="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 right-0 box border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 mr-10">
        <div className="mr-4 text-slate-600 dark:text-slate-200">Dark Mode</div>
        <div className="form-switch">
        <input
                            type="checkbox"
                            name="status"
                            onChange={isDarkTheme}
                            className="form-check-input"
                          /></div>
    </div>
      <div className="App">
        {isAuth !== null && window.location.pathname!=="/job-application" ? (
          <>
            <All.MobileNav />
            <div className="flex mt-[4.7rem] md:mt-0">
              <All.Sidenav />
              <div className="content">
                <All.Header isLogout={isLogout} />
                <Routing isdark={isdark} />

                <All.Footer />
              </div>
            </div>
          </>
        ) : (
           window.location.pathname==="/job-application" ? <All.JobApplication isLogin={isLogin}/>
          :<All.Login isLogin={isLogin} />
        )}
      </div>
    </Router>
    </ToastProvider>

  );
}

export default App;

// const mapStateToProps = ({ auth }) => {
//   const { currentTheme } =  auth;
//   return { currentTheme }
// };
// const mapDispatchToProps = {
// 	onSwitchTheme,
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App)

