import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import Modal from "react-modal";
import HashLoader from "react-spinners/HashLoader";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useLoadingContext } from "react-router-loading";
Modal.setAppElement("#root");

const Feeds = () => {
  const loadingContext = useLoadingContext();
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [imgsrc, setimgsrc] = useState("");
  const [isopen, setIsOpen] = useState(null);
  const [date, setdate] = useState(new Date());
  const params = useParams();

  useEffect(() => {
    getData("");

    const loading = async () => {
      loadingContext.done();
    };

    loading();
  }, []);

  const handledate = (e) => {
    let date;
    if (e !== null) {
      setdate(e);
      date = format(new Date(e), "yyyy-MM-dd");
    } else {
      date = format(new Date(), "yyyy-MM-dd");
    }
    getData(date);
  };

  const nexTDay = () => {
    let tomorrow = date;
    tomorrow.setDate(date.getDate() + 1);

    setdate(tomorrow);
    let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
    getData(date2);
  };

  const prevDay = () => {
    let tomorrow = date;
    tomorrow.setDate(date.getDate() - 1);

    setdate(tomorrow);
    let date2 = format(new Date(tomorrow), "yyyy-MM-dd");
    getData(date2);
  };

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;

    if (rowData.employee !== "") {
      return (
        <a
          href={"/employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.username}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  const columns = [
    {
      title: "Name",
      field: "username",
      formatter: reactFormatter(<DetailVal />),
    },
    { title: "Action", field: "action" },
    { title: "Date Time", field: "datetime" },
    { title: "Friendly Date", field: "friendlydate" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (date) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-feeds/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
        date,
        type: 1,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setattdata(res.data);
        } else {
          setattdata([
            {
              username: "No data Available",
              action: "",
              datetime: "",
              friendlydate: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const rowClick = (e, row) => {
    const rowData = row._row.data;

    setimgsrc(
      `https://portal.rayvat.com/assets/uploads/screenshot/${rowData.username}/${rowData.date}/${rowData.screenshot}`
    );
    setIsOpen(true);
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Feeds</h2>
      </div>

      <div className="intro-y box p-5 mt-5">
        <div
          className="overflow-x-auto h-50 scrollbar-hidden"
          style={{ minHeight: "700px" }}
        >
          <div className="h-auto">
            <div className="text-left">
              <label>Select Date</label> <br></br>
              <DatePicker
                clearIcon=""
                calendarIcon={
                  <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                }
                onChange={handledate}
                value={date}
              />
              <button className="mx-2" onClick={prevDay}>
                <GrPrevious />
              </button>{" "}
              <button className="mx-2" onClick={nexTDay}>
                <GrNext />
              </button>
            </div>
          </div>

          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              events={{
                rowClick: rowClick,
              }}
              options={options}
              // data-custom-attr="test-custom-attribute"
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Feeds;
