import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import { FaCheckCircle, FaCalendar } from "react-icons/fa";

import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { format } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { HashLoader } from "react-spinners";

const MonthlyAttandance = () => {
  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  let monthNumber = month[today.getMonth()];

  let [present, setpresent] = useState(0);
  let [absent, setabsent] = useState(0);
  let [onleave, setonleave] = useState(0);
  let [holiday, setholiday] = useState(0);
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  const currmonth = monthNumber;
  const curryear = today.getFullYear();
  const [selectedmonth, setselectedmonth] = useState({ year: curryear, month: Number(currmonth) });  
  const params = useParams();

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");

    getData({ year: year, month: month });
  }, []);

  const columns = [
    { title: "Date", field: "date", width: 150 },
    {
      title: "First In",
      field: "in",
      formatter: reactFormatter(<SimpleButton />),
    },
    { title: "Last Out", field: "out" },
    { title: "Duration", field: "duration" },
    { title: "Type", field: "type" },
    {
      title: "Status",
      field: "status",
      formatter: reactFormatter(<StatusVal />),
    },
    {
      title: "Action",
      field: "action",
      formatter: reactFormatter(<ActionVal />),
    },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.status === 1 ? (
          <FaCheckCircle style={{ fontSize: "15px" }} />
        ) : null}
      </>
    );
  }

  function ActionVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.status === 1 && rowData.first_in !== 'weekoff' ? (
          <span className="present">P</span>
        ) : rowData.status === 0 || rowData.first_in === 'weekoff' ? (
          <span className="absent">AB</span>
        ) : null}
      </>
    );
  }
  function SimpleButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <span
          className={rowData.in === "Absent" ? "text-danger" : "text-primary"}
        >
          {rowData.in}
        </span>
      </>
    );
  }

  const options = {
    movableRows: true,
    movableColumns: true,
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async ({ year, month }) => {
    setLoading(true);

    let pmonth;
    const obj = {
      month: month,
      year: year,
    };
    localStorage.setItem("checkdate", obj);
    if (year === "") {
      pmonth = format(new Date(), "MM");
    } else {
      pmonth = month;
    }
    let pyear;
    if (year === "") {
      pyear = format(new Date(), "yyyy");
    } else {
      pyear = year;
    }

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-monthwise-attendance/${params.uuid}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month: pmonth,
        year: pyear,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let attarr = [];
          if (res.attdata.length > 0) {
            setonleave(res.onleave);
            setholiday(res.holiday);
            setpresent(res.present);
            setabsent(res.attdata.length - res.present);
            res.attdata.forEach((element) => {
              let date = format(new Date(element.date), "yyyy-MM-dd")
              let data = {
                date: date,
                in: element.first_in === "00:00:00" ? "Absent" : element.first_in,
                out: element.last_out === "00:00:00" ? "" : element.last_out,
                duration: element.first_in === "00:00:00" ? "" : element.duration,
                type: element.first_in === "00:00:00" || element.first_in === "Weekof"  || element.first_in === "Holiday"? "" : "Full Day",
                status: element.first_in === "00:00:00"  || element.first_in === "Weekof"  || element.first_in === "Holiday" ? 0 : 1,
                action: "More Details",
              };

              attarr.push(data);
            });
          }

          setattdata(attarr);
        } else {
          setattdata([
            {
              date: "No data Available",
              in: "",
              out: "",
              duration: "",
              type: "",
              status: "",
              action: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handleOnDismiss = () => {
    setisVisible(false);
  };

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthname = monthNames[month - 1];
    localStorage.setItem(
      "monthyear",
      JSON.stringify({ year, monthname, month: month })
    );
    setisVisible(false);
    setmonthYear({ year, month: monthname });
    setselectedmonth({ year, month: Number(monthname) });
    getData({ year, month });
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value
      ? value.monthname
      : monthYear && monthYear.month
      ? monthYear.month
      : 0;
    const year = value
      ? value.year
      : monthYear && monthYear.year
      ? monthYear.year
      : 0;

    let date = new Date();
    let curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };
  return (
    <>
      <div
        className="intro-y flex flex-col sm:flex-row items-center mt-8 "
        style={{ justifyContent: "space-between" }}
      >
        <h2 className="text-lg font-medium ">Monthly Attandance</h2>

        <div className="flex ">
          <div class="ml-2 intro-y">
            <div class="box p-2 flex items-center zoom-in">
              <div class="font-medium">Present: {present}</div>
            </div>
          </div>
          <div class="ml-2 intro-y">
            <div class="box p-2 flex items-center zoom-in">
              <div class="font-medium">Absent: {absent}</div>
            </div>
          </div>
          <div class="ml-2 intro-y">
            <div class="box p-2  flex items-center zoom-in">
              <div class="font-medium">On Leave: {onleave}</div>
            </div>
          </div>

          <div class="ml-2  intro-y">
            <div class="box p-2  flex items-center zoom-in">
              <div class="font-medium">Holiday: {holiday} </div>
            </div>
          </div>
        </div>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div className="flex mt-5 sm:mt-0">
            <div className="input-group mr-2 calenderdate">
              <input
                type="text"
                style={{ fontSize: "12px" }}
                onFocus={(e) => showMonthPicker(e)}
                value={getMonthValue()}
                className="form-control calenderdate1"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
              <div className="input-group-append calendaricon">
                <FaCalendar
                  style={{ fontSize: "12px" }}
                  onClick={(e) => showMonthPicker(e)}
                />
              </div>
            </div>
            {loading ? (
              <div className="text-center m-5 p-5">
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              </div>
            ) : (
              <ReactMonthPicker
                show={isVisible}
                lang={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                value={selectedmonth}
                onChange={handleOnChange}
                onDismiss={handleOnDismiss}
              />
            )}
          </div>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          <ReactTabulator
            columns={columns}
            data={attdata}
            options={options}
            className="mt-5 table-report table-report--tabulator"
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyAttandance;
