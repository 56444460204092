import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { ReactTabulator } from "react-tabulator";
import { format } from "date-fns";
import { HashLoader } from "react-spinners";
import { useLoadingContext } from "react-router-loading";

const Attendance = () => {
  const [attdata, setattdata] = useState(null);
  let [loading, setLoading] = useState(false);
  const loadingContext = useLoadingContext();

  useEffect(() => {
    getData();
    
  }, []);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    console.log(userdata);
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    console.log(userdata.id);
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getUserDetails/${userdata.id}`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type: 0,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let arr = res.attdata;
          let attarr = [];
          if (arr.length > 0) {
            arr.forEach((element) => {
              let date1 = new Date(
                format(new Date(element.date), "yyyy-MM-dd") +
                  " " +
                  element.start_time
              );
              let date2 = new Date(
                format(new Date(element.date), "yyyy-MM-dd") +
                  " " +
                  element.end_time
              );
              let diff = date2.getTime() - date1.getTime();
              let msec = diff;
              let hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;

              let mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              let ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              let mmm = mm >= 10 ? mm : "0" + mm;
              let hhh = hh >= 10 ? hh : "0" + hh;
              let sss = ss >= 10 ? ss : "0" + ss;

              let punchdata = res.attdata.filter((item) => {
                let returnitem;
                if (
                  format(new Date(item.date), "yyyy-MM-dd") ===
                  format(new Date(element.date), "yyyy-MM-dd")
                ) {
                  returnitem = item;
                }
                return returnitem;
              });

              let daystart = punchdata[0].start_time;
              let dayend = punchdata[punchdata.length - 1].end_time;
              let diff1;

              if (dayend !== "00:00:00") {
                let date_start = new Date(
                  format(new Date(element.date), "yyyy-MM-dd") + " " + daystart
                );
                let date_end = new Date(
                  format(new Date(element.date), "yyyy-MM-dd") + " " + dayend
                );

                diff1 = date_start.getTime() - date_end.getTime();
              } else {
                diff1 = 0;
              }

              let msec1 = diff1;
              let hh1 = Math.floor(msec1 / 1000 / 60 / 60);
              msec1 -= hh1 * 1000 * 60 * 60;

              let mm1 = Math.floor(msec1 / 1000 / 60);
              msec1 -= mm1 * 1000 * 60;
              let ss1 = Math.floor(msec1 / 1000);
              msec1 -= ss1 * 1000;

              let mmm1 = mm1 >= 10 ? mm1 : "0" + mm1;
              let hhh1 = hh1 >= 10 ? hh1 : "0" + hh1;
              let sss1 = ss1 >= 10 ? ss1 : "0" + ss1;

              let data = {
                name: element.name,
                date: format(new Date(element.date), "yyyy-MM-dd"),
                in: element.start_time,
                out: element.end_time,
                duration:
                  element.end_time !== "00:00:00"
                    ? hhh + ":" + mmm + ":" + sss
                    : "-",
                day_duration:
                  dayend !== "00:00:00" ? hhh1 + ":" + mmm1 + ":" + sss1 : "-",
                details: "More Details",
              };

              attarr.push(data);
            });
          }

          setattdata(attarr);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  const columns = [
    { title: "Date", field: "date", width: 150 },
    { title: "In", field: "in" },
    { title: "Out", field: "out" },
    { title: "Duration", field: "duration" },
  ];

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Attandance</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <form id="tabulator-html-filter-form" className="xl:flex sm:mr-auto">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </form>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          {loading ? (
            <HashLoader size={30} />
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Attendance;
