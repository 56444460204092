import React from "react";
import Chart from "react-apexcharts";
import equal from "fast-deep-equal";

class ApexChart extends React.Component {
  componentDidUpdate(prevProps) {
    if (!equal(this.props.attdata, prevProps.attdata)) {
      this.setState({
        series: [
          {
            name: this.props.type,
            data: this.props.attdata[0].value,
            color: '#FFC435'
          },
        ],
        options: {
          chart: {
            height: 350,
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            categories: this.props.attdata[0].dates,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      });
    }
  }
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: props.type,
          data: props.attdata[0].value,
          color: '#FFC435'
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: props.attdata[0].dates,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      },
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="area"
        height={300}
      />
    );
  }
}

export default ApexChart;
