import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { useParams } from "react-router-dom";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator.min.css";
import { ReactTabulator, reactFormatter } from "react-tabulator";
import "react-month-picker/css/month-picker.css";
import { FaFileDownload } from "react-icons/fa";

import { HashLoader } from "react-spinners";

const Documents = () => {
  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const params = useParams();

  useEffect(() => {
    getData("");
  }, []);

  const columns = [
    { title: "Title", field: "title" },
    {
      title: "Download",
      field: "download",
      formatter: reactFormatter(<StatusVal />),
    },
    // { title: "Last Updated Date", field: "uploaded_date" },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.download !== "" ? (
          <a
            rel="noreferrer"
            href={
              "https://portal.rayvat.com/assets/uploads/myalldocuserdoc/" +
              rowData.download
            }
            target="_blank"
            download
          >
            <FaFileDownload
              style={{
                fontSize: "20px",
                color: "rgb(30 64 175 / let(--tw-bg-opacity))",
              }}
            />
          </a>
        ) : null}
      </>
    );
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async (date) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-documents/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          if (res.data.length > 0) {
            let arr = [];
            // console.log(res.data);
            res.data.forEach((element) => {
              for (const key in element) {
                if (Object.hasOwnProperty.call(element, key)) {
                  const element1 = element[key];
                  // console.log(element1);
                  let data = {
                    title: key.replaceAll("_", " "),
                    download: element1,
                    uploaded_date: element.uploaded_date,
                  };
                  arr.push(data);
                }
              }
            });
            setattdata(arr);
          }
        } else {
          setattdata([
            {
              title: "No data Available",
              download: "",
              uploaded_date: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">Documents</h2>
      </div>

      <div className="intro-y box">
        <div className="scrollbar-hidden">
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Documents;
